import {Component, OnInit} from '@angular/core';
import postgresText from '../../../../../../assets/js/tutorial-texts/PostgresTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-postgres-functions',
  templateUrl: './postgres-functions.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class PostGresFunctionsPageComponent implements OnInit {

  header = postgresText.Functions.header;
  steps: Step[] = postgresText.Functions.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {PostGresFunctionsPageComponent};
