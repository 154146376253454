import {Component, OnInit} from '@angular/core';
import cssText from '../../../../../../assets/js/tutorial-texts/CssTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-css-responsiveness',
  templateUrl: './css-responsiveness.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class CssResponsivenessPageComponent implements OnInit {

  header = cssText.Responsiveness.header;
  steps: Step[] = cssText.Responsiveness.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {CssResponsivenessPageComponent};
