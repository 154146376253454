import { Component } from '@angular/core';
import $ from 'jquery';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import angularCards from '../../assets/js/cards/AngularCards.json';
import consulCards from '../../assets/js/cards/ConsulCards.json';
import cssCards from '../../assets/js/cards/CssCards.json';
import cucumberCards from '../../assets/js/cards/CucumberCards.json';
import dockerCards from '../../assets/js/cards/DockerCards.json';
import elasticCards from '../../assets/js/cards/ElasticSearchCards.json';
import gitlabCards from '../../assets/js/cards/GitlabCards.json';
import integrationCards from '../../assets/js/cards/IntegrationCards.json';
import htmlCards from '../../assets/js/cards/HtmlCards.json';
import jasmineCards from '../../assets/js/cards/JasmineCards.json';
import javaCards from '../../assets/js/cards/JavaCards.json';
import javascriptCards from '../../assets/js/cards/JavaScriptCards.json';
import jenkinsCards from '../../assets/js/cards/JenkinsCards.json';
import jqueryCards from '../../assets/js/cards/JQueryCards.json';
import mavenCards from '../../assets/js/cards/MavenCards.json';
import mongoCards from '../../assets/js/cards/MongoCards.json';
import mysqlCards from '../../assets/js/cards/MysqlCards.json';
import nexusCards from '../../assets/js/cards/NexusCards.json';
import nomadCards from '../../assets/js/cards/NomadCards.json';
import postgresCards from '../../assets/js/cards/PostgresCards.json';
import springCards from '../../assets/js/cards/SpringCards.json';
import unitCards from '../../assets/js/cards/UnitCards.json';
import websitesCards from '../../assets/js/cards/WebsitesCards.json';
import Swal from 'sweetalert2';

interface Link {
  timestamp: string;
  header: string;
  text: string;
  href: string;
}

@Component({
  selector: 'app-code-header',
  templateUrl: './code-page-header.component.html',
  styleUrls: ['./code-page-header.component.less']
})
class CodePageHeaderComponent {

  highLightedHeader = '';
  showDropDown = false;
  showSmallHeader = false;
  showSmallInnerHeader = false;
  showSmallInnerInnerHeader = false;
  currentHeaderIndex = 0;

  dropDownTypes = {
    frontend: {
      dropDownColumns: ['HTML', 'CSS', 'Javascript', 'JQuery', 'Angular'],
      html: htmlCards,
      css: cssCards,
      javascript: javascriptCards,
      jquery: jqueryCards,
      angular: angularCards
    },
    backend: {
      dropDownColumns: ['Java', 'Spring', 'Maven'],
      java: javaCards,
      spring: springCards,
      maven: mavenCards
    },
    database: {
      dropDownColumns: ['MySQL', 'PostgreSQL', 'Mongo', 'Elastic Search'],
      mysql: mysqlCards,
      postgresql: postgresCards,
      mongo: mongoCards,
      elasticSearch: elasticCards
    },
    devops: {
      dropDownColumns: ['Gitlab', 'Jenkins', 'Nexus', 'Nomad', 'Docker', 'Consul'],
      gitlab: gitlabCards,
      jenkins: jenkinsCards,
      nexus: nexusCards,
      nomad: nomadCards,
      docker: dockerCards,
      consul: consulCards
    },
    setup: {
      dropDownColumns: ['Build your own Website'],
      buildYourOwnWebsite: websitesCards
    },
    test: {
      dropDownColumns: ['Unit Testing', 'Integration Tests', 'Jasmine', 'Cucumber'],
      unitTesting: unitCards,
      integrationTests: integrationCards,
      jasmine: jasmineCards,
      cucumber: cucumberCards
    },
  };

  dropDownColumns: string[] = [];
  dropDownColumnLinks: Link[][] = [];

  constructor(private route: ActivatedRoute, private readonly router: Router) {
    route.url.subscribe((urlSegments: UrlSegment[]) => {
      if (urlSegments.length > 0) {
        this.highLightedHeader = urlSegments[0].path;
      }
    });
  }

  changeCurrent(type: string): void {
    this.dropDownColumns = [];
    this.dropDownColumnLinks = [];
    if (type === 'frontend') {
      this.dropDownColumns = this.dropDownTypes.frontend.dropDownColumns;
      this.dropDownColumnLinks.push(
        this.dropDownTypes.frontend.html,
        this.dropDownTypes.frontend.css,
        this.dropDownTypes.frontend.javascript,
        this.dropDownTypes.frontend.jquery,
        this.dropDownTypes.frontend.angular);
    } else if (type === 'backend') {
      this.dropDownColumns = this.dropDownTypes.backend.dropDownColumns;
      this.dropDownColumnLinks.push(
        this.dropDownTypes.backend.java,
        this.dropDownTypes.backend.spring,
        this.dropDownTypes.backend.maven);
    } else if (type === 'database') {
      this.dropDownColumns = this.dropDownTypes.database.dropDownColumns;
      this.dropDownColumnLinks.push(
        this.dropDownTypes.database.mysql,
        this.dropDownTypes.database.postgresql,
        this.dropDownTypes.database.mongo,
        this.dropDownTypes.database.elasticSearch);
    } else if (type === 'devops') {
      this.dropDownColumns = this.dropDownTypes.devops.dropDownColumns;
      this.dropDownColumnLinks.push(
        this.dropDownTypes.devops.gitlab,
        this.dropDownTypes.devops.jenkins,
        this.dropDownTypes.devops.nexus,
        this.dropDownTypes.devops.nomad,
        this.dropDownTypes.devops.docker,
        this.dropDownTypes.devops.consul);
    } else if (type === 'setup') {
      this.dropDownColumns = this.dropDownTypes.setup.dropDownColumns;
      this.dropDownColumnLinks.push(
        this.dropDownTypes.setup.buildYourOwnWebsite);
    } else if (type === 'test') {
      this.dropDownColumns = this.dropDownTypes.test.dropDownColumns;
      this.dropDownColumnLinks.push(
        this.dropDownTypes.test.unitTesting,
        this.dropDownTypes.test.integrationTests,
        this.dropDownTypes.test.jasmine,
        this.dropDownTypes.test.cucumber);
    } else {
      this.dropDownColumns = [];
      this.dropDownColumnLinks = [];
    }
  }

  dropDownHeader(type: string): void {
    const header = $('#codeHeader');
    const headerClass = $('.code-header');
    const blur = $('.blur-window');
    if (type === 'frontend') {
      header[0].style.height = '50vh';
      headerClass[0].style.height = '50vh';
    } else if (type === 'backend') {
      header[0].style.height = '70vh';
      headerClass[0].style.height = '70vh';
    } else if (type === 'database') {
      header[0].style.height = '40vh';
      headerClass[0].style.height = '40vh';
    } else if (type === 'devops') {
      header[0].style.height = '40vh';
      headerClass[0].style.height = '40vh';
    } else if (type === 'setup') {
      header[0].style.height = '30vh';
      headerClass[0].style.height = '30vh';
    } else if (type === 'test') {
      header[0].style.height = '30vh';
      headerClass[0].style.height = '30vh';
    } else {
      header[0].style.height = '50vh';
      headerClass[0].style.height = '50vh';
    }
    blur[0].style.opacity = '1';
    this.showDropDown = true;
    const dropDownTutorials = $('.drop-down-tutorials')[0];
    if (dropDownTutorials !== undefined) {
      dropDownTutorials.style.height = '100%';
    }
  }

  foldHeader(): void {
    const header = $('#codeHeader');
    const headerClass = $('.code-header');
    const blur = $('.blur-window');
    const dropDownTutorials = $('.drop-down-tutorials');
    header[0].style.height = '59px';
    headerClass[0].style.height = '59px';
    header[0].style.background = 'transparent';
    blur[0].style.opacity = '0';
    dropDownTutorials[0].style.height = '0';
    this.showDropDown = false;
  }

  toggleSmallHeader(): void {
    if (!this.showSmallHeader && this.showSmallInnerHeader) {
      this.showSmallInnerHeader = false;
    } else if (!this.showSmallHeader && !this.showSmallInnerHeader && this.showSmallInnerInnerHeader) {
      this.showSmallInnerInnerHeader = false;
    } else {
      this.showSmallHeader = !this.showSmallHeader;
    }
  }

  toggleHamburger(): void {
    const bars = document.querySelectorAll('.bar');
    bars.forEach(bar => bar.classList.toggle('x'));
  }

  showInnerHeader(): void {
    this.showSmallHeader = false;
    this.showSmallInnerHeader = true;
    this.showSmallInnerInnerHeader = false;
  }

  showInnerInnerHeader(index: number): void {
    this.showSmallHeader = false;
    this.showSmallInnerHeader = false;
    this.showSmallInnerInnerHeader = true;
    this.currentHeaderIndex = index;
    setTimeout(() => {
      const ul = $('.code-small-header-ul-inner-inner');
      console.log(this.dropDownColumnLinks[this.currentHeaderIndex][0].href);
      if (this.dropDownColumnLinks[this.currentHeaderIndex][0].href.includes('java')) {
        ul[0].style.overflowY = 'scroll';
      } else {
        ul[0].style.overflowY = 'hidden';
      }
    }, 100);
  }

  goBack(): void {
    if (this.showSmallHeader) {
      this.showSmallHeader = false;
      this.showSmallInnerHeader = false;
      this.showSmallInnerInnerHeader = true;
    } else if (this.showSmallInnerHeader) {
      this.showSmallHeader = true;
      this.showSmallInnerHeader = false;
      this.showSmallInnerInnerHeader = false;
    } else if (this.showSmallInnerInnerHeader) {
      this.showSmallHeader = false;
      this.showSmallInnerHeader = true;
      this.showSmallInnerInnerHeader = false;
    } else {
      this.showSmallHeader = false;
      this.showSmallInnerHeader = false;
      this.showSmallInnerInnerHeader = false;
    }
  }

  goTo(link: Link): void {
    if (link.timestamp.startsWith(' - ')) {
      Swal.fire('Coming soon', 'This document is under work in progress!', 'error');
      $('.swal2-modal')[0].style.backgroundColor = 'rgba(255,255,255,.9)';
      $('.swal2-title')[0].style.color = 'black';
      $('.swal2-content')[0].style.color = 'black';
      $('.swal2-styled.swal2-confirm')[0].style.backgroundColor = 'rgb(155, 118, 83) !important';
      $('.swal2-styled.swal2-confirm')[0].style.borderColor = 'rgba(190, 135, 61) !important';
    } else {
      this.router.navigate([link.href]);
    }
  }
}

export { CodePageHeaderComponent };
