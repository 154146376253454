import {Component, OnInit} from '@angular/core';
import elasticSearchText from '../../../../../../assets/js/tutorial-texts/ElasticSearchTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-elastic-search-queries',
  templateUrl: './elastic-queries.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class ElasticSearchQueriesPageComponent implements OnInit {

  header = elasticSearchText.Queries.header;
  steps: Step[] = elasticSearchText.Queries.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {ElasticSearchQueriesPageComponent};
