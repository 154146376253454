import {Component, OnInit} from '@angular/core';
import postgresText from '../../../../../../assets/js/tutorial-texts/PostgresTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-postgres-what-is',
  templateUrl: './postgres-what-is.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class PostGresWhatIsPageComponent implements OnInit {

  header = postgresText.WhatIs.header;
  steps: Step[] = postgresText.WhatIs.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {PostGresWhatIsPageComponent};
