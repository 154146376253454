import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HomePageComponent} from './homepage/home-page.component';
import {TutorialsPageComponent} from './tutorialspage/tutorials-page.component';
import {PortfolioPageComponent} from './portfoliopage/portfolio-page.component';
import {AboutPageComponent} from './aboutpage/about-page.component';
import {ArtPageComponent} from './artpage/art-page.component';
import {MusicPageComponent} from './musicpage/music-page.component';
import {HeadersModule} from './headers/headers.module';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {JavaGettingStartedPageComponent} from './tutorialspage/pages/java/lessons/gettingstarted/java-getting-started.component';
import {AngularGettingStartedPageComponent} from './tutorialspage/pages/angular/lessons/gettingstarted/angular-getting-started.component';
import {AngularLesson1PageComponent} from './tutorialspage/pages/angular/lessons/lesson1/angular-lesson-1.component';
import {AngularLesson2PageComponent} from './tutorialspage/pages/angular/lessons/lesson2/angular-lesson-2.component';
import {AngularLesson3PageComponent} from './tutorialspage/pages/angular/lessons/lesson3/angular-lesson-3.component';
import {AngularLesson4PageComponent} from './tutorialspage/pages/angular/lessons/lesson4/angular-lesson-4.component';
import {AngularLesson5PageComponent} from './tutorialspage/pages/angular/lessons/lesson5/angular-lesson-5.component';
import {AngularLesson6PageComponent} from './tutorialspage/pages/angular/lessons/lesson6/angular-lesson-6.component';
import {CssBasicPageComponent} from './tutorialspage/pages/css/lessons/basic/css-basic.component';
import {CssShadowsPageComponent} from './tutorialspage/pages/css/lessons/shadows/css-shadows.component';
import {CssAlignmentsPageComponent} from './tutorialspage/pages/css/lessons/alignments/css-alignments.component';
import {CssGradientsPageComponent} from './tutorialspage/pages/css/lessons/gradients/css-gradients.component';
import {CssResponsivenessPageComponent} from './tutorialspage/pages/css/lessons/responsiveness/css-responsiveness.component';
import {CssTransitionsPageComponent} from './tutorialspage/pages/css/lessons/transitions/css-transitions.component';
import {CssCarouselPageComponent} from './tutorialspage/pages/css/lessons/carousel/css-carousel.component';
import {CssHeaderPageComponent} from './tutorialspage/pages/css/lessons/header/css-header.component';
import {ElasticSearchWhatIsPageComponent} from './tutorialspage/pages/elastic/lessons/whatis/elastic-what-is.component';
import {ElasticSearchGettingStartedPageComponent} from './tutorialspage/pages/elastic/lessons/gettingstarted/elastic-getting-started.component';
import {ElasticSearchQueriesPageComponent} from './tutorialspage/pages/elastic/lessons/queries/elastic-queries.component';
import {HtmlGettingStartedPageComponent} from './tutorialspage/pages/html/lessons/gettingstarted/html-getting-started.component';
import {HtmlElementsPageComponent} from './tutorialspage/pages/html/lessons/elements/html-elements.component';
import {HtmlBoostrapPageComponent} from './tutorialspage/pages/html/lessons/bootstrap/html-bootstrap.component';
import {JavaLesson1PageComponent} from './tutorialspage/pages/java/lessons/lesson1/java-lesson-1.component';
import {JavaLesson2PageComponent} from './tutorialspage/pages/java/lessons/lesson2/java-lesson-2.component';
import {JavaLesson23PageComponent} from './tutorialspage/pages/java/lessons/lesson23/java-lesson-23.component';
import {JavaLesson22PageComponent} from './tutorialspage/pages/java/lessons/lesson22/java-lesson-22.component';
import {JavaLesson21PageComponent} from './tutorialspage/pages/java/lessons/lesson21/java-lesson-21.component';
import {JavaLesson20PageComponent} from './tutorialspage/pages/java/lessons/lesson20/java-lesson-20.component';
import {JavaLesson19PageComponent} from './tutorialspage/pages/java/lessons/lesson19/java-lesson-19.component';
import {JavaLesson18PageComponent} from './tutorialspage/pages/java/lessons/lesson18/java-lesson-18.component';
import {JavaLesson17PageComponent} from './tutorialspage/pages/java/lessons/lesson17/java-lesson-17.component';
import {JavaLesson16PageComponent} from './tutorialspage/pages/java/lessons/lesson16/java-lesson-16.component';
import {JavaLesson15PageComponent} from './tutorialspage/pages/java/lessons/lesson15/java-lesson-15.component';
import {JavaLesson14PageComponent} from './tutorialspage/pages/java/lessons/lesson14/java-lesson-14.component';
import {JavaLesson13PageComponent} from './tutorialspage/pages/java/lessons/lesson13/java-lesson-13.component';
import {JavaLesson12PageComponent} from './tutorialspage/pages/java/lessons/lesson12/java-lesson-12.component';
import {JavaLesson11PageComponent} from './tutorialspage/pages/java/lessons/lesson11/java-lesson-11.component';
import {JavaLesson10PageComponent} from './tutorialspage/pages/java/lessons/lesson10/java-lesson-10.component';
import {JavaLesson9PageComponent} from './tutorialspage/pages/java/lessons/lesson9/java-lesson-9.component';
import {JavaLesson8PageComponent} from './tutorialspage/pages/java/lessons/lesson8/java-lesson-8.component';
import {JavaLesson7PageComponent} from './tutorialspage/pages/java/lessons/lesson7/java-lesson-7.component';
import {JavaLesson6PageComponent} from './tutorialspage/pages/java/lessons/lesson6/java-lesson-6.component';
import {JavaLesson5PageComponent} from './tutorialspage/pages/java/lessons/lesson5/java-lesson-5.component';
import {JavaLesson4PageComponent} from './tutorialspage/pages/java/lessons/lesson4/java-lesson-4.component';
import {JavaLesson3PageComponent} from './tutorialspage/pages/java/lessons/lesson3/java-lesson-3.component';
import {JavascriptGettingStartedPageComponent} from './tutorialspage/pages/javascript/lessons/gettingstarted/javascript-getting-started.component';
import {JavascriptLesson1PageComponent} from './tutorialspage/pages/javascript/lessons/lesson1/javascript-lesson-1.component';
import {JavascriptLesson2PageComponent} from './tutorialspage/pages/javascript/lessons/lesson2/javascript-lesson-2.component';
import {JavascriptLesson3PageComponent} from './tutorialspage/pages/javascript/lessons/lesson3/javascript-lesson-3.component';
import {JavascriptLesson4PageComponent} from './tutorialspage/pages/javascript/lessons/lesson4/javascript-lesson-4.component';
import {JavascriptLesson5PageComponent} from './tutorialspage/pages/javascript/lessons/lesson5/javascript-lesson-5.component';
import {JavascriptLesson6PageComponent} from './tutorialspage/pages/javascript/lessons/lesson6/javascript-lesson-6.component';
import {JavascriptLesson7PageComponent} from './tutorialspage/pages/javascript/lessons/lesson7/javascript-lesson-7.component';
import {JqueryGettingStartedPageComponent} from './tutorialspage/pages/jquery/lessons/gettingstarted/jquery-getting-started.component';
import {JqueryLesson1PageComponent} from './tutorialspage/pages/jquery/lessons/lesson1/jquery-lesson-1.component';
import {JqueryLesson2PageComponent} from './tutorialspage/pages/jquery/lessons/lesson2/jquery-lesson-2.component';
import {JqueryLesson3PageComponent} from './tutorialspage/pages/jquery/lessons/lesson3/jquery-lesson-3.component';
import {JqueryLesson4PageComponent} from './tutorialspage/pages/jquery/lessons/lesson4/jquery-lesson-4.component';
import {JqueryLesson5PageComponent} from './tutorialspage/pages/jquery/lessons/lesson5/jquery-lesson-5.component';
import {JqueryLesson6PageComponent} from './tutorialspage/pages/jquery/lessons/lesson6/jquery-lesson-6.component';
import {JqueryLesson7PageComponent} from './tutorialspage/pages/jquery/lessons/lesson7/jquery-lesson-7.component';
import {MavenWhatIsPageComponent} from './tutorialspage/pages/maven/lessons/whatis/maven-what-is.component';
import {MavenGettingStartedPageComponent} from './tutorialspage/pages/maven/lessons/gettingstarted/maven-getting-started.component';
import {MavenDependenciesPageComponent} from './tutorialspage/pages/maven/lessons/dependencies/maven-dependencies.component';
import {MongoWhatIsPageComponent} from './tutorialspage/pages/mongo/lessons/whatis/mongo-what-is.component';
import {MongoGettingStartedPageComponent} from './tutorialspage/pages/mongo/lessons/gettingstarted/mongo-getting-started.component';
import {MongoCollectionsPageComponent} from './tutorialspage/pages/mongo/lessons/collection/mongo-collection.component';
import {MongoQueriesPageComponent} from './tutorialspage/pages/mongo/lessons/queries/mongo-queries.component';
import {MySQLWhatIsPageComponent} from './tutorialspage/pages/mysql/lessons/whatis/mysql-what-is.component';
import {MySQLGettingStartedPageComponent} from './tutorialspage/pages/mysql/lessons/gettingstarted/mysql-getting-started.component';
import {MySQLQueriesPageComponent} from './tutorialspage/pages/mysql/lessons/queries/mysql-queries.component';
import {MySQLAlterPageComponent} from './tutorialspage/pages/mysql/lessons/alter/mysql-alter.component';
import {MySQLJoinsPageComponent} from './tutorialspage/pages/mysql/lessons/joins/mysql-joins.component';
import {MySQLViewsPageComponent} from './tutorialspage/pages/mysql/lessons/views/mysql-views.component';
import {MySQLFunctionsPageComponent} from './tutorialspage/pages/mysql/lessons/functions/mysql-functions.component';
import {MySQLProceduresPageComponent} from './tutorialspage/pages/mysql/lessons/procedures/mysql-procedures.component';
import {PostGresWhatIsPageComponent} from './tutorialspage/pages/postgres/lessons/whatis/postgres-what-is.component';
// tslint:disable-next-line:max-line-length
import {PostGresGettingStartedPageComponent} from './tutorialspage/pages/postgres/lessons/gettingstarted/postgres-getting-started.component';
import {PostGresQueriesPageComponent} from './tutorialspage/pages/postgres/lessons/queries/postgres-queries.component';
import {PostGresAlterPageComponent} from './tutorialspage/pages/postgres/lessons/alter/postgres-alter.component';
import {PostGresJoinsPageComponent} from './tutorialspage/pages/postgres/lessons/joins/postgres-joins.component';
import {PostGresViewsPageComponent} from './tutorialspage/pages/postgres/lessons/views/postgres-views.component';
import {PostGresFunctionsPageComponent} from './tutorialspage/pages/postgres/lessons/functions/postgres-functions.component';
import {PostGresProceduresPageComponent} from './tutorialspage/pages/postgres/lessons/procedures/postgres-procedures.component';
import {SpringWhatIsPageComponent} from './tutorialspage/pages/spring/lessons/springwhatis/spring-what-is.component';
import {SpringAnnotationsPageComponent} from './tutorialspage/pages/spring/lessons/annotations/spring-annotations.component';
import {SpringbootPageComponent} from './tutorialspage/pages/spring/lessons/boot/spring-boot.component';
import {SpringSwaggerPageComponent} from './tutorialspage/pages/spring/lessons/swagger/spring-swagger.component';
import {SpringSecurityPageComponent} from './tutorialspage/pages/spring/lessons/security/spring-security.component';
import {SpringKafkaPageComponent} from './tutorialspage/pages/spring/lessons/kafka/spring-kafka.component';
import {SpringWebSocketsPageComponent} from './tutorialspage/pages/spring/lessons/websockets/spring-web-sockets.component';
import {GalleryPageComponent} from './gallerypage/gallery-page.component';
import {ServicesPageComponent} from './servicespage/services-page.component';
import {ContactPageComponent} from './contactpage/contact-page.component';
import {ResumePageComponent} from './resumepage/resume-page.component';
import {IntegrityPageComponent} from './integritypolicypage/integrity-page.component';
import {TermsAndConditionsPageComponent} from './termsandconditionspage/terms-and-conditions-page.component';
import {CommonTutorialPageComponent} from './tutorialspage/pages/common/common-tutorial-page.component';

@NgModule({
  declarations: [HomePageComponent, AboutPageComponent, GalleryPageComponent, ArtPageComponent, MusicPageComponent,
    ServicesPageComponent, PortfolioPageComponent, ResumePageComponent, TutorialsPageComponent, ContactPageComponent,
    IntegrityPageComponent, TermsAndConditionsPageComponent,
    AngularGettingStartedPageComponent, AngularLesson1PageComponent,
    AngularLesson2PageComponent, AngularLesson3PageComponent, AngularLesson4PageComponent, AngularLesson5PageComponent,
    AngularLesson6PageComponent,
    CssBasicPageComponent, CssShadowsPageComponent, CssAlignmentsPageComponent,
    CssGradientsPageComponent, CssResponsivenessPageComponent, CssTransitionsPageComponent,
    CssCarouselPageComponent, CssHeaderPageComponent,
    ElasticSearchWhatIsPageComponent, ElasticSearchGettingStartedPageComponent, ElasticSearchQueriesPageComponent,
    HtmlGettingStartedPageComponent, HtmlElementsPageComponent, HtmlBoostrapPageComponent,
    JavaGettingStartedPageComponent, JavaLesson1PageComponent, JavaLesson2PageComponent,
    JavaLesson3PageComponent, JavaLesson4PageComponent, JavaLesson5PageComponent, JavaLesson6PageComponent,
    JavaLesson7PageComponent, JavaLesson8PageComponent, JavaLesson9PageComponent, JavaLesson10PageComponent,
    JavaLesson11PageComponent, JavaLesson12PageComponent, JavaLesson13PageComponent, JavaLesson14PageComponent,
    JavaLesson15PageComponent, JavaLesson16PageComponent, JavaLesson17PageComponent, JavaLesson18PageComponent,
    JavaLesson19PageComponent, JavaLesson20PageComponent, JavaLesson21PageComponent, JavaLesson22PageComponent,
    JavaLesson23PageComponent,
    JavascriptGettingStartedPageComponent, JavascriptLesson1PageComponent, JavascriptLesson2PageComponent,
    JavascriptLesson3PageComponent, JavascriptLesson4PageComponent, JavascriptLesson5PageComponent, JavascriptLesson6PageComponent,
    JavascriptLesson7PageComponent,
    JqueryGettingStartedPageComponent, JqueryLesson1PageComponent, JqueryLesson2PageComponent,
    JqueryLesson3PageComponent, JqueryLesson4PageComponent, JqueryLesson5PageComponent, JqueryLesson6PageComponent,
    JqueryLesson7PageComponent,
    MavenWhatIsPageComponent, MavenGettingStartedPageComponent, MavenDependenciesPageComponent,
    MongoWhatIsPageComponent, MongoGettingStartedPageComponent, MongoCollectionsPageComponent,
    MongoQueriesPageComponent,
    MySQLWhatIsPageComponent, MySQLGettingStartedPageComponent, MySQLQueriesPageComponent, MySQLAlterPageComponent,
    MySQLJoinsPageComponent, MySQLViewsPageComponent, MySQLFunctionsPageComponent, MySQLProceduresPageComponent,
    PostGresWhatIsPageComponent, PostGresGettingStartedPageComponent, PostGresQueriesPageComponent,
    PostGresAlterPageComponent, PostGresJoinsPageComponent, PostGresViewsPageComponent, PostGresFunctionsPageComponent,
    PostGresProceduresPageComponent,
    SpringWhatIsPageComponent, SpringAnnotationsPageComponent, SpringbootPageComponent,
    SpringSwaggerPageComponent, SpringSecurityPageComponent, SpringKafkaPageComponent, SpringWebSocketsPageComponent,
    CommonTutorialPageComponent
  ],
  imports: [CommonModule, HeadersModule, HttpClientModule, BrowserModule],
  exports: [HomePageComponent, AboutPageComponent, GalleryPageComponent, ArtPageComponent, MusicPageComponent,
    ServicesPageComponent, PortfolioPageComponent, ResumePageComponent, TutorialsPageComponent, ContactPageComponent,
    IntegrityPageComponent, TermsAndConditionsPageComponent,
    AngularGettingStartedPageComponent, AngularLesson1PageComponent, AngularLesson2PageComponent,
    AngularLesson3PageComponent, AngularLesson4PageComponent, AngularLesson5PageComponent, AngularLesson6PageComponent,
    CssBasicPageComponent, CssShadowsPageComponent, CssAlignmentsPageComponent, CssGradientsPageComponent,
    CssResponsivenessPageComponent, CssTransitionsPageComponent, CssCarouselPageComponent, CssHeaderPageComponent,
    ElasticSearchWhatIsPageComponent, ElasticSearchGettingStartedPageComponent, ElasticSearchQueriesPageComponent,
    HtmlGettingStartedPageComponent, HtmlElementsPageComponent, HtmlBoostrapPageComponent,
    JavaGettingStartedPageComponent, JavaLesson1PageComponent, JavaLesson2PageComponent, JavaLesson3PageComponent,
    JavaLesson4PageComponent, JavaLesson5PageComponent, JavaLesson6PageComponent, JavaLesson7PageComponent, JavaLesson8PageComponent,
    JavaLesson9PageComponent, JavaLesson10PageComponent, JavaLesson11PageComponent, JavaLesson12PageComponent, JavaLesson13PageComponent,
    JavaLesson14PageComponent, JavaLesson15PageComponent, JavaLesson16PageComponent, JavaLesson17PageComponent, JavaLesson18PageComponent,
    JavaLesson19PageComponent, JavaLesson20PageComponent, JavaLesson21PageComponent, JavaLesson22PageComponent, JavaLesson23PageComponent,
    JavascriptGettingStartedPageComponent, JavascriptLesson1PageComponent, JavascriptLesson2PageComponent,
    JavascriptLesson3PageComponent, JavascriptLesson4PageComponent, JavascriptLesson5PageComponent, JavascriptLesson6PageComponent,
    JavascriptLesson7PageComponent,
    JqueryGettingStartedPageComponent, JqueryLesson1PageComponent, JqueryLesson2PageComponent,
    JqueryLesson3PageComponent, JqueryLesson4PageComponent, JqueryLesson5PageComponent, JqueryLesson6PageComponent,
    JqueryLesson7PageComponent,
    MavenWhatIsPageComponent, MavenGettingStartedPageComponent, MavenDependenciesPageComponent,
    MongoWhatIsPageComponent, MongoGettingStartedPageComponent, MongoCollectionsPageComponent,
    MongoQueriesPageComponent,
    MySQLWhatIsPageComponent, MySQLGettingStartedPageComponent, MySQLQueriesPageComponent, MySQLAlterPageComponent,
    MySQLJoinsPageComponent, MySQLViewsPageComponent, MySQLFunctionsPageComponent, MySQLProceduresPageComponent,
    PostGresWhatIsPageComponent, PostGresGettingStartedPageComponent, PostGresQueriesPageComponent,
    PostGresAlterPageComponent, PostGresJoinsPageComponent, PostGresViewsPageComponent, PostGresFunctionsPageComponent,
    PostGresProceduresPageComponent,
    SpringWhatIsPageComponent, SpringAnnotationsPageComponent, SpringbootPageComponent,
    SpringSwaggerPageComponent, SpringSecurityPageComponent, SpringKafkaPageComponent, SpringWebSocketsPageComponent,
    CommonTutorialPageComponent
  ]
})
class PagesModule {
}

export {PagesModule};
