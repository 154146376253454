import {Component, OnInit} from '@angular/core';
import htmlText from '../../../../../../assets/js/tutorial-texts/HtmlTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-html-boostrap',
  templateUrl: './html-bootstrap.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class HtmlBoostrapPageComponent implements OnInit {

  header = htmlText.Bootstrap.header;
  steps: Step[] = htmlText.Bootstrap.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {HtmlBoostrapPageComponent};
