import {Component, OnInit} from '@angular/core';
import springText from '../../../../../../assets/js/tutorial-texts/SpringTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-spring-web-sockets',
  templateUrl: './spring-web-sockets.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class SpringWebSocketsPageComponent implements OnInit {

  header = springText.Websockets.header;
  steps: Step[] = springText.Websockets.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {SpringWebSocketsPageComponent};
