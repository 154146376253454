<app-main-header></app-main-header>
<div class="row no-margins music-body">
  <div class="col-xs-12 no-padding">
    <div class="music-body-background-image"></div>
    <div class="row no-margins">
      <div class="col-xs-12 no-padding">
        <div class="music-body-audio-player">
          <div class="inline-flex action-buttons">
            <span (click)="previousSong()" class="glyphicon glyphicon-step-backward"></span>
            <span (click)="playSong()" class="glyphicon glyphicon-play"></span>
            <span (click)="pauseSong()" class="glyphicon glyphicon-pause"></span>
            <span (click)="stopSong()" class="glyphicon glyphicon-stop"></span>
            <span (click)="nextSong()" class="glyphicon glyphicon-step-forward"></span>
          </div>
        </div>
        <div class="music-body-audio-player-current-song-title">
          <p> [ {{currentSongTitle}} </p>
        </div>
        <div id="content">
          <canvas id="canvas"></canvas>
        </div>
      </div>
    </div>
  </div>
</div>
