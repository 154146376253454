import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.less']
})
class ServicesPageComponent implements OnInit {

  inverseHeaderColor = false;
  html = document.documentElement;
  frameCount = 1071;
  image = new Image();
  context: CanvasRenderingContext2D = undefined as any as CanvasRenderingContext2D;
  scrollNewYorkImgSrc = this.currentFrame(1);
  allImagesLoaded = false;

  ngOnInit(): void {
    Promise.all(Array.from(document.images)
    .filter(img => !img.complete)
    .map(img => new Promise(resolve => { img.onload = img.onerror = resolve; })))
    .then(() => {
      console.log('images finished loading');
      this.allImagesLoaded = true;
    });
  }

  currentFrame(index: number): string {
    return './assets/images/scroll/newyork/' + index.toString().padStart(3, '0') + '.jpg';
  }

  updateImage(index: number): void {
    this.scrollNewYorkImgSrc = this.currentFrame(index + 1);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    const scrollTop = this.html.scrollTop;
    const maxScrollTop = this.html.scrollHeight - window.innerHeight;
    const scrollFraction = scrollTop / maxScrollTop;
    const frameIndex = Math.min(this.frameCount - 1, Math.floor(scrollFraction * this.frameCount));
    requestAnimationFrame(() => this.updateImage(frameIndex));

    this.inverseHeaderColor = this.isInView('services-body-2')
      || this.isInView('services-body-4')
      || this.isInView('services-body-6');
  }

  isInView(className: string): boolean {
    const element = document.getElementsByClassName(className)[0];
    const topPos = element.getBoundingClientRect().top + window.scrollY;
    const bottomPos = element.getBoundingClientRect().bottom + window.scrollY;
    return window.pageYOffset > topPos && window.pageYOffset < bottomPos;
  }
}

export { ServicesPageComponent };
