<app-main-header [inverseHeaderColor]="inverseHeaderColor"></app-main-header>
<div style="opacity: 0">
  <div class="non-showing-images" *ngFor="let item of [].constructor(frameCount); let i = index">
    <img style="position: absolute; top: 0" loading="lazy" src="./assets/images/scroll/tokyo/{{(i+1).toString().padStart(3, '0')}}.jpg" alt="">
  </div>
</div>
<div class="row no-margins about-body-scroll" (scroll)="onScroll()">
  <div class="col-xs-12 about-body-scroll-canvas no-padding">
    <img *ngIf="!allImagesLoaded" loading="lazy" src="./assets/images/scroll/tokyo/001.jpg" alt="">
    <img *ngIf="allImagesLoaded" loading="lazy" src="{{scrollTokyoImgSrc}}" alt="">
  </div>
</div>

<div class="row no-margins about-body-1">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <div class="about-title-box">
          <h1>ABOUT</h1>
          <h1 style="position: relative;top: -20px;">GABRIEL</h1>
          <p>Welcome to my website. The name is Gabriel Eriksson and this is who I am.</p>
          <p>I am born and raised in Stockholm, Sweden and came to life back in 1984.</p>
          <p>But when I really came to life was when I started my travelling. I have been to many places and will not stop exploring new areas.</p>
          <p>My priorities in life is to always keep your mind busy and constantly learning new abilities.</p>
          <p>Some of my interests have been with me since birth such as music and art, but later I found out that programming must have been one of them as well.</p>
          <p>Have a look below and click your way through to read more about my different areas of learning.</p>
        </div>
      </div>
      <div class="col-xs-6"></div>
    </div>
  </div>
</div>

<div class="row no-margins about-body-2">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-6"></div>
      <div class="col-sm-4">
        <div class="about-title-box">
          <h1 style="-webkit-text-stroke: 1px black;">Go</h1>
          <h1 style="color: black; position: relative;top: -20px;">TRAVELLING</h1>
          <p class="black">As I said, I love to travel and I've been to many places.</p>
          <p class="black">Usually when I go somewhere I'd like to reach as far as possible.</p>
          <p class="black">I've been to Hawaii islands, Maldives, Australia, Los Angeles, New York, Las Vegas, Miami, Egypt, Italy, Spain, Hong Kong, Taiwan and many more.</p>
          <p class="black">But my absolute favorite place on earth would have to be Japan where I studied the language and lived there for a while.</p>
          <p class="black">Japan really has a lot to offer and I will always recommend it with all my heart.</p>
          <p class="black">Travel and you will not only see new things or places, you also add to your experiences in self-growing and world awareness.</p>
        </div>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</div>

<div class="row no-margins about-body-3">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <div class="about-title-box">
          <h1>Make</h1>
          <h1 style="position: relative;top: -20px;">Music</h1>
          <p>My music interest started at young age. I was about 5 years old when I said to my mother that I also wanted to be able to play the piano.</p>
          <p>She said: - Just listen, son. Listen and try to find the same sound.</p>
          <p>Long story short, tens of thousands hours of practising really did its work.</p>
          <p>I also started singing and boy do I love singing as well.</p>
          <p>Music heals you. It can get you calm. It can get you excited. It can bring so many emotions to the surface.</p>
          <p>All you got to do, is Listen.</p>
        </div>
      </div>
      <div class="col-xs-6"></div>
    </div>
  </div>
</div>

<div class="row no-margins about-body-4">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-6"></div>
      <div class="col-sm-4">
        <div class="about-title-box">
          <h1 style="-webkit-text-stroke: 1px black;">Love</h1>
          <h1 style="color: black; position: relative;top: -20px;">Creative Art</h1>
          <p class="black">Drawing.</p>
          <p class="black">Drawing is also one of my core interests.</p>
          <p class="black">It takes time and really tests your patience.</p>
          <p class="black">I've been drawing ever since I was a kid and I never stopped.</p>
          <p class="black">Some day I will go back to Japan and draw what I see.</p>
          <p class="black">Drawing is creating.</p>
        </div>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</div>

<div class="row no-margins about-body-5">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <div class="about-title-box">
          <h1>Develop</h1>
          <h1 style="position: relative;top: -20px;">Anything you want</h1>
          <p>In 2014 I started my journey in coding.</p>
          <p>Since my brother told me I can't just wander the world. You have your clarity in that already, now you need clarity in your own corner stones.</p>
          <p>So, I studied to get started building my corner stones. One of them is steady income.</p>
          <p>One thing I've learnt is that you can fail in what you don't want so you might as well take a chance to do what you love.</p>
          <p>And what better way to get a steady income from, than from something you love?</p>
          <p>Coding is not just a job.</p>
          <p>If you think about it, all my interest have one thing in common.</p>
          <p>Creation</p>
          <br>
          <p>That is the one word that has always defined me.</p>
        </div>
      </div>
      <div class="col-xs-6"></div>
    </div>
  </div>
</div>
