import {Component, OnInit} from '@angular/core';
import mavenText from '../../../../../../assets/js/tutorial-texts/MavenTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-maven-dependencies',
  templateUrl: './maven-dependencies.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class MavenDependenciesPageComponent implements OnInit {

  header = mavenText.Dependencies.header;
  steps: Step[] = mavenText.Dependencies.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {MavenDependenciesPageComponent};
