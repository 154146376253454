<app-main-header></app-main-header>
<div style="opacity: 0">
  <div class="non-showing-images" *ngFor="let item of [].constructor(frameCount); let i = index">
    <img style="position: absolute; top: 0" loading="lazy" src="./assets/images/scroll/lasvegas/{{(i+1).toString().padStart(3, '0')}}.jpg" alt="">
  </div>
</div>
<div class="row no-margins resume-body-scroll" (scroll)="onScroll()">
  <div class="col-xs-12 resume-body-scroll-canvas no-padding">
    <img *ngIf="!allImagesLoaded" loading="lazy" src="./assets/images/scroll/lasvegas/001.jpg" alt="">
    <img *ngIf="allImagesLoaded" loading="lazy" src="{{scrollLasVegasImgSrc}}" alt="">
  </div>
</div>

<div class="row no-margins resume-body-1">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-2">
        <img class="profile-image" src="./assets/images/ProfileImage.jpg" alt="">
      </div>
      <div class="col-sm-4">
        <div class="resume-title-box">
          <h1>RESUME</h1>
          <p>Name: Gabriel Eriksson</p>
          <p>Developer since 2014</p>
          <p>Occupation: System Developer</p>
          <p>City: Stockholm, Sweden</p>
          <p>Languages spoken: Swedish, English, Japanese</p>
        </div>
      </div>
      <div class="col-xs-6"></div>
    </div>
  </div>
</div>

<div class="row no-margins resume-body-2">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-6"></div>
      <div class="col-sm-4">
        <div class="resume-title-box">
          <img src="../../assets/images/Logos/NackademinLogo1.png" alt="">
          <p class="grey">Nackademin</p>
          <p class="grey"><span>2014-08 - 2016-05</span></p>
          <p class="grey">Student</p>
          <p class="black">
            This is were my programming career started. I took the course in learning the Java language.
            But this course also included learning the meaning of fullstack.
            So from learning the basics of Java and JavaEE to learning the basics in HTML, CSS, LESS, Mysql, PostgreSQL, C#, Angular, Javascript, JQuery, Tomcat, Wildfly, Webdesign and a lot more.
            My first project was my very own Sonic the Hedgehog like game and I had my internship at Unifaun with a friend where we got to design and implement their address book.
          </p>
        </div>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</div>

<div class="row no-margins resume-body-3">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <div class="resume-title-box">
          <img src="../../assets/images/Logos/UnifaunLogo1.png" alt="">
          <p class="bold">Unifaun</p>
          <p class="bold"><span>2015-08 - 2016-05</span></p>
          <p class="bold">Fullstack Developer</p>
          <p>
            Unifaun was my internship from Nackademin. I was sent here with a friend from school for two semesters.
            The first semester was to develop Unifaun's Address Book which they use to create, read, update and delete their merchant contact details.
            The second semester we got divided and jumped into real environment teams. I got the enterprise team.
            Here I developed the enterprise customer sites such as DHL and IKEA.
          </p>
          <p>
            The stack was built with micro services and docker using languages such as AngularJS up front and Spring MVC as the logic layer and Mysql and Elastic Search at the bottom.
            They also had a legacy stack using Struts 1 & 2.
            For testing we used Cucumber and Jasmine for FrontEnd and automizing tests. And Junit for unit testing.
          </p>
        </div>
      </div>
      <div class="col-xs-6"></div>
    </div>
  </div>
</div>

<div class="row no-margins resume-body-4">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-6"></div>
      <div class="col-sm-4">
        <div class="resume-title-box">
          <img src="../../assets/images/Logos/TrustlyLogo1.png" alt="">
          <p class="grey">Trustly</p>
          <p class="grey"><span>2016-06 - 2018-04</span></p>
          <p class="grey">Backend Developer</p>
          <p class="black">
            Trustly was the first real step into my future career. I was employed to become a so called Robocop.
            Meaning building web crawler robots for guiding users and fulfill a payment online.
            But not only robots were made. We also got to integrate with the bank's APIs to make an even faster payment.
            I initiated a project making their web crawling core service to become 16 times faster by making the routing know exactly where to go.
            I also created a better graphical observation interface where we could instantly see what needed to be done in what country.
          </p>
          <p class="black">
            The stack was built in jars running on physical servers. We used Javascript as a pulling service connected to PostgreSQL for payment states to decide what content to show to the user.
            The Java layer was triggered by PostgreSQL and then collected data and creating payments by using either web crawling or API integration.
          </p>
        </div>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</div>

<div class="row no-margins resume-body-5">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <div class="resume-title-box">
          <img src="../../assets/images/Logos/PricerunnerLogo1.png" alt="">
          <p class="bold">Pricerunner</p>
          <p class="bold"><span>2018-04 - 2021-02</span></p>
          <p class="bold">Backend Developer</p>
          <p>
            Pricerunner is currently where I learnt the most I've ever learned about different systems.
            Since Pricerunner was being called back from Shanghai tech department we needed to migrate the old system to a new one.
            I ended up in the Merchant team developing the merchant and brand controlling interface. Also was in partial charge of the click flow which is the main corporal income.
            I initated and created the public API project increasing Pricerunner's total income. I also initiated and created their back office interfaced called Hive.
          </p>
          <p>
            We used GigaSpaces for in-memory database due to a faster comparison site.
            It was built with micro services using docker and Consul for service discovery and key value store.
            As a long term persistance layer we used PostgreSQL and Elastic Search and MongoDB.
            For Front End we had React and TypeScript using GraphQL and NodeJS.
            Back End used Spring MVC with an ELK stack for logging.
          </p>
        </div>
      </div>
      <div class="col-xs-6"></div>
    </div>
  </div>
</div>

<div class="row no-margins resume-body-6">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-6"></div>
      <div class="col-sm-4">
        <div class="resume-title-box">
          <img src="../../assets/images/Logos/CagTvvLogo1Black.png" alt="">
          <p class="grey">CAG - Tillväxtverket</p>
          <p class="grey"><span>2021-02 -</span></p>
          <p class="grey">Fullstack Developer</p>
          <p class="black">
            Tillväxtverket is currently where I am deployed from CAG as a consultant.
            They have gotten a mission from the Swedish government to handle all incoming short time working funding requests from companies in need due to COVID 19.
            Here I am developing their website platform for applying the short time working from the state and also their platform for handling all incoming applications.
          </p>
          <p class="black">
            Techniques used here is AngularJS and Angular2+ for the FrontEnd developing and Java together with Scala as the BackEnd code.
            My first mission was to initiate their micro service platform for new services.
            And to implement the first services using this docker compose based solution.
            For this we used SpringBoot.
          </p>
        </div>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</div>
