import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-music-page',
  templateUrl: './music-page.component.html',
  styleUrls: ['./music-page.component.less']
})
class MusicPageComponent implements OnInit {

  songTitles = [
    'Tifas Theme',
    'Melodies Of Life',
    'Besaid Island',
    'Suteki Da Ne',
    'Those Who Fight',
    'Suikoden',
    'Deserts Serenade',
    'Khal´Duls Race',
    'Ending Theme',
    'Zulucat Happy Land',
    'The Great Launch'
  ];
  currentSongTitle: string = this.songTitles[0];
  currentSongIndex = 0;

  audio = new Audio();
  context = new AudioContext();
  src: any;
  songPaused = false;
  currentTimeInSong = 0;

  ngOnInit(): void {
    $('body')[0].style.overflowY = 'scroll';
  }

  nextSong(): void {
    this.currentSongIndex++;
    if (this.currentSongIndex > 10) {
      this.currentSongIndex = 0;
    }
    this.currentSongTitle = this.songTitles[this.currentSongIndex];
  }

  previousSong(): void {
    this.currentSongIndex--;
    if (this.currentSongIndex < 0) {
      this.currentSongIndex = 10;
    }
    this.currentSongTitle = this.songTitles[this.currentSongIndex];
  }

  playSong(): void {
    const currentSong = this.replaceAll(this.currentSongTitle, ' ', '');
    if (this.songPaused) {
      const src = '../assets/music/' + currentSong + '.mp3';
      this.audio = new Audio(this.replaceAll(src, ' ', ''));
      this.audio.currentTime = this.currentTimeInSong;
      this.audio.load();
      this.runRenderVisualizer();
      this.audio.play();
      this.songPaused = false;
    }
    else if (!this.isPlaying()) {
      this.stopSong();
      const src = '../assets/music/' + currentSong + '.mp3';
      this.audio = new Audio(this.replaceAll(src, ' ', ''));
      this.audio.load();
      this.runRenderVisualizer();
      this.audio.play();
      this.songPaused = false;
    } else {
      this.stopSong();
      const src = '../assets/music/' + currentSong + '.mp3';
      this.audio = new Audio(this.replaceAll(src, ' ', ''));
      this.audio.load();
      this.runRenderVisualizer();
      this.audio.play();
      this.songPaused = false;
    }
  }

  pauseSong(): void {
    if (this.isPlaying()) {
      this.audio.pause();
      this.songPaused = true;
      this.currentTimeInSong = this.audio.currentTime;
    }
  }

  stopSong(): void {
    if (this.isPlaying() || this.pauseSong) {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.currentTimeInSong = 0;
      this.songPaused = false;
    }
  }

  runRenderVisualizer(): void {
    const analyser = this.context.createAnalyser();
    analyser.connect(this.context.destination);
    analyser.fftSize = 256;
    this.src = this.context.createMediaElementSource(this.audio);
    this.src.connect(analyser);
    const canvas: any = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;
    const barWidth = (WIDTH / bufferLength) * 2.5;
    let barHeight;
    let x = 0;
    function renderFrame(): void {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      requestAnimationFrame(renderFrame);
      x = 0;
      analyser.getByteFrequencyData(dataArray);
      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i];
        const opacity = Math.random();
        let r = 255 * opacity;
        let g = 255 * opacity;
        let b = 255 * opacity;
        if (r < 230) {
          r = 230;
        }
        if (g < 230) {
          g = 230;
        }
        if (b < 230) {
          b = 230;
        }
        ctx.fillStyle = 'rgb(' + r + ',' + g + ',' + b + ')';
        ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);
        x += barWidth + 1;
      }
    }
    renderFrame();
  }

  isPlaying(): boolean {
    return this.audio
        && this.audio.currentTime > 0
        && !this.audio.paused
        && !this.audio.ended
        && this.audio.readyState > 2;
  }

  replaceAll(str: string, find: string, replace: string): string {
    const escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    return str.replace(new RegExp(escapedFind, 'g'), replace);
  }
}

export { MusicPageComponent };
