import {Component, OnInit} from '@angular/core';
import jqueryText from '../../../../../../assets/js/tutorial-texts/JqueryTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-jquery-lesson-6',
  templateUrl: './jquery-lesson-6.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class JqueryLesson6PageComponent implements OnInit {

  header = jqueryText.Lesson6.header;
  steps: Step[] = jqueryText.Lesson6.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {JqueryLesson6PageComponent};
