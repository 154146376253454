import {Component, OnInit} from '@angular/core';
import mavenText from '../../../../../../assets/js/tutorial-texts/MavenTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-maven-what-is',
  templateUrl: './maven-what-is.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class MavenWhatIsPageComponent implements OnInit {

  header = mavenText.WhatIs.header;
  steps: Step[] = mavenText.WhatIs.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {MavenWhatIsPageComponent};
