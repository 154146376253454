<app-code-header></app-code-header>
<div class="row no-margins code-page-home-body">
  <div clasS="col-xs-0 col-sm-2"></div>
  <div clasS="col-xs-12 col-sm-8">
    <div class="code-title-box animated fadeIn">
      <h1 style="-webkit-text-stroke: 1px black;">Code</h1>
      <h1 class="black" style="position: relative;top: -20px;">Tutorials</h1>
      <p class="black">Welcome to my code chamber.</p>
      <p class="black">In here I am gathering all the information I find useful. Things that I have been learning during the years and so on.</p>
      <p class="black">You'll see all from a bunch of coding examples and some tips & tricks to code styles and conventions</p>
      <br>
      <h4 class="black">Why did I chose coding</h4>
      <p class="black">First of all, coding is a lot of fun. It makes you understand logic on a whole different level. For once you can control the computer what to do.</p>
      <p class="black">You can create anything basically. Whether it is a website, automatic watering system to a game, phone app or a mining algorithm service.</p>
      <p class="black">But hey, I'm not gonna lie. Getting there is a long way ahead and this is just my journey of pursuing the dream and using many of my ways of coding.</p>
      <p class="black">Feel free to explore this chamber.</p>
    </div>
  </div>
</div>
