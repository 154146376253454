<app-main-header></app-main-header>
<div class="row no-margins terms-and-conditions-body animated fadeIn slow">
  <div class="col-xs-0 col-sm-3 no-padding"></div>
  <div class="col-xs-12 col-sm-6 no-padding">
    <div class="information-box">
      <h1 class="black">Terms & Conditions</h1>
      <p class="black bold">Introduction</p>
      <p class="black">This website creating service is run by GE Websites (In this agreement GE Websites is referred to as <span class="bold">"GE Websites"</span>, <span class="bold">"we"</span>, <span class="bold">"us"</span>, <span class="bold">"our"</span> ). GE Websites is registered in Sweden (??????-????).</p>
      <p class="black">The following terms and conditions govern the use of the service, which is provided by GE Websites. By using this Service, you are deemed to have accepted these terms and conditions ("Terms of Use").</p>
      <br>
      <p class="black bold">Rights by Law</p>
      <p class="black">GE Websites disclaims all responsibility when it comes to:</p>
      <ul>
        <li class="black">Managing material owned by other than customer</li>
        <li class="black">Information on website has inappropriate content</li>
        <li class="black">Content on website is of false accusations</li>
      </ul>
      <br>
      <p class="black">It is of great importance that the customer makes sure that requested content is approved to use legally.</p>
      <br>
      <p class="black bold">Agreement</p>
      <p class="black">By accepting our terms and conditions you understand that:</p>
      <ul>
        <li class="black">GE Websites can hold personal data if data is of requested package.</li>
        <li class="black">A fulfillment invoice will be sent to you as the website goes live.</li>
        <li class="black">A monthly invoice can be sent to you in case of maintenance requested by you or web provider.</li>
        <li class="black">GE Websites holds no responsibility in support time limitations.</li>
        <li class="black">GE Websites gives no refunds</li>
        <li class="black">GE Websites owns your website</li>
        <li class="black">To the greatest extent permitted by current legislation, we disclaim all liability as a result of reliance on any content available via the Booking Service. You bear the entire risk as to the completeness, accuracy or usefulness of any content that you access through our Service.</li>
        <li class="black">In no event shall we be liable for any consequential, incidental or special damages or for loss of operating profit, production interruption, loss of business information, loss of data or loss of any benefit resulting from your use or inability to use our Services.</li>
      </ul>
      <br>
      <p class="black bold">Contact us</p>
      <p class="black">You are welcome to contact us with any questions related to these Terms of Use <a href="Contact">here</a>.</p>
    </div>
  </div>
  <div class="col-xs-0 col-sm-3 no-padding"></div>
</div>
