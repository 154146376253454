import {Component, OnInit} from '@angular/core';
import javaText from '../../../../../../assets/js/tutorial-texts/JavaTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-java-lesson-17',
  templateUrl: './java-lesson-17.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class JavaLesson17PageComponent implements OnInit {

  header = javaText.Lesson17.header;
  steps: Step[] = javaText.Lesson17.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {JavaLesson17PageComponent};
