import {Component, OnInit} from '@angular/core';
import mysqlText from '../../../../../../assets/js/tutorial-texts/MysqlTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-mysql-procedures',
  templateUrl: './mysql-procedures.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class MySQLProceduresPageComponent implements OnInit {

  header = mysqlText.Procedures.header;
  steps: Step[] = mysqlText.Procedures.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {MySQLProceduresPageComponent};
