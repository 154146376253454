import {Component, OnInit} from '@angular/core';
import mongoText from '../../../../../../assets/js/tutorial-texts/MongoTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-mongo-collections',
  templateUrl: './mongo-collection.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class MongoCollectionsPageComponent implements OnInit {

  header = mongoText.Collection.header;
  steps: Step[] = mongoText.Collection.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {MongoCollectionsPageComponent};
