import {Component, OnInit} from '@angular/core';
import elasticSearchText from '../../../../../../assets/js/tutorial-texts/ElasticSearchTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-elastic-search-what-is',
  templateUrl: './elastic-what-is.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class ElasticSearchWhatIsPageComponent implements OnInit {

  header = elasticSearchText.WhatIs.header;
  steps: Step[] = elasticSearchText.WhatIs.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {ElasticSearchWhatIsPageComponent};
