import {Component, OnInit} from '@angular/core';
import htmlText from '../../../../../../assets/js/tutorial-texts/HtmlTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-html-elements',
  templateUrl: './html-elements.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class HtmlElementsPageComponent implements OnInit {

  header = htmlText.Elements.header;
  steps: Step[] = htmlText.Elements.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {HtmlElementsPageComponent};
