import {Component, OnInit} from '@angular/core';
import {Step} from '../../../common/common-tutorial-page.component';
import angularText from '../../../../../../assets/js/tutorial-texts/AngularTexts.json';

@Component({
  selector: 'app-angular-lesson-5',
  templateUrl: './angular-lesson-5.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class AngularLesson5PageComponent implements OnInit {

  header = angularText.Lesson5.header;
  steps: Step[] = angularText.Lesson5.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {AngularLesson5PageComponent};
