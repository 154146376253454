import {Component, OnInit} from '@angular/core';
import cssText from '../../../../../../assets/js/tutorial-texts/CssTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-css-gradients',
  templateUrl: './css-gradients.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class CssGradientsPageComponent implements OnInit {

  header = cssText.Gradients.header;
  steps: Step[] = cssText.Gradients.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {CssGradientsPageComponent};
