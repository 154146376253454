import {Component, OnInit} from '@angular/core';
import mysqlText from '../../../../../../assets/js/tutorial-texts/MysqlTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-mysql-views',
  templateUrl: './mysql-views.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class MySQLViewsPageComponent implements OnInit {

  header = mysqlText.Views.header;
  steps: Step[] = mysqlText.Views.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {MySQLViewsPageComponent};
