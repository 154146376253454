import {Component, OnInit} from '@angular/core';
import javaText from '../../../../../../assets/js/tutorial-texts/JavaTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-java-getting-started',
  templateUrl: './java-getting-started.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class JavaGettingStartedPageComponent implements OnInit {

  header = javaText.GettingStarted.header;
  steps: Step[] = javaText.GettingStarted.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {JavaGettingStartedPageComponent};
