import {Component, OnInit} from '@angular/core';
import jqueryText from '../../../../../../assets/js/tutorial-texts/JqueryTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-jquery-lesson-5',
  templateUrl: './jquery-lesson-5.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class JqueryLesson5PageComponent implements OnInit {

  header = jqueryText.Lesson5.header;
  steps: Step[] = jqueryText.Lesson5.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {JqueryLesson5PageComponent};
