<app-main-header></app-main-header>
<div class="row no-margins gallery-body animated fadeIn slow">
  <div class="col-sm-6 no-padding">
    <a href="Music">
      <img src="./assets/images/background/PianoBackground.jpeg" alt="">
    </a>
  </div>
  <div class="col-sm-6 no-padding">
    <a href="Art">
      <img src="./assets/images/background/ArtBackground.jpeg" alt="">
    </a>
  </div>
</div>
