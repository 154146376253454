import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { CodePageHeaderComponent } from './code-page-header.component';
import { MainHeaderComponent } from './main-header.component';

@NgModule({
  declarations: [
    MainHeaderComponent,
    CodePageHeaderComponent
  ],
  imports: [
    BrowserModule
  ],
  exports: [ MainHeaderComponent, CodePageHeaderComponent ],
  providers: [],
  bootstrap: [ MainHeaderComponent, CodePageHeaderComponent ]
})
export class HeadersModule { }
