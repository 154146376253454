import {Component, Input, OnInit} from '@angular/core';
import $ from 'jquery';
import {ActivatedRoute, UrlSegment} from '@angular/router';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.less']
})
class MainHeaderComponent implements OnInit {

  initialHighLightedHeader = '';
  highLightedHeader = '';
  showSmallHeader = false;

  headers = {
    home: {
      left: '-87.4%'
    },
    about: {
      left: '-74.8%'
    },
    gallery: {
      left: '-62.2%'
    },
    services: {
      left: '-49.6%'
    },
    portfolio: {
      left: '-37%'
    },
    contact: {
      left: '-24.4%'
    },
    tutorials: {
      left: '-11.8%'
    }
  };

  @Input()
  inverseHeaderColor = false;

  constructor(private route: ActivatedRoute) {
    route.url.subscribe((urlSegments: UrlSegment[]) => {
      if (urlSegments.length > 0) {
        this.initialHighLightedHeader = urlSegments[0].path;
        this.highLightedHeader = urlSegments[0].path;
      }
    });
  }

  ngOnInit(): void {
    const headerValue = this.initialHighLightedHeader === '' ? 'home' : this.initialHighLightedHeader;
    this.setSpanValue(headerValue.toLowerCase());
  }

  toggleHamburger(): void {
    const bars = document.querySelectorAll('.bar');
    bars.forEach(bar => bar.classList.toggle('x'));
  }

  toggleSmallHeader(): void {
    this.showSmallHeader = !this.showSmallHeader;
    const smallHeader = $('.small-header-body');
    if (!this.showSmallHeader) {
      smallHeader[0].style.height = '59px';
      smallHeader[0].style.backgroundColor = 'transparent';
    } else {
      smallHeader[0].style.height = '100vh';
      smallHeader[0].style.backgroundColor = 'rgba(0,0,0,.8)';
    }
  }

  setCurrentHighlight(headerValue: string): void {
    this.highLightedHeader = headerValue === 'Home' ? '' : headerValue;
    this.setSpanValue(headerValue);
  }

  setCurrentHighlightDefault(): void {
    this.highLightedHeader = this.initialHighLightedHeader;
    const headerValue = this.initialHighLightedHeader === '' ? 'home' : this.initialHighLightedHeader;
    this.setSpanValue(headerValue.toLowerCase());
  }

  setSpanValue(headerValue: string): void {
    const span = $('.border-bottom-span');
    let left = '0';
    if (headerValue === 'home') {
      left = this.headers.home.left;
    } else if (headerValue === 'about') {
      left = this.headers.about.left;
    } else if (headerValue === 'gallery') {
      left = this.headers.gallery.left;
    } else if (headerValue === 'services') {
      left = this.headers.services.left;
    } else if (headerValue === 'portfolio') {
      left = this.headers.portfolio.left;
    } else if (headerValue === 'contact') {
      left = this.headers.contact.left;
    } else if (headerValue === 'tutorials') {
      left = this.headers.tutorials.left;
    }
    span[0].style.left = left;
  }
}

export { MainHeaderComponent };
