import {Component, OnInit} from '@angular/core';
import jqueryText from '../../../../../../assets/js/tutorial-texts/JqueryTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-jquery-getting-started',
  templateUrl: './jquery-getting-started.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class JqueryGettingStartedPageComponent implements OnInit {

  header = jqueryText.GettingStarted.header;
  steps: Step[] = jqueryText.GettingStarted.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {JqueryGettingStartedPageComponent};
