import {Component, OnInit} from '@angular/core';
import javascriptText from '../../../../../../assets/js/tutorial-texts/JavascriptTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-javascript-lesson-4',
  templateUrl: './javascript-lesson-4.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class JavascriptLesson4PageComponent implements OnInit {

  header = javascriptText.Lesson4.header;
  steps: Step[] = javascriptText.Lesson4.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {JavascriptLesson4PageComponent};
