import {Component} from '@angular/core';

@Component({
  selector: 'app-integrity-page',
  templateUrl: './integrity-page.component.html',
  styleUrls: ['./integrity-page.component.less']
})
class IntegrityPageComponent {
}

export { IntegrityPageComponent };
