import {Component, OnInit} from '@angular/core';
import mavenText from '../../../../../../assets/js/tutorial-texts/MavenTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-maven-getting-started',
  templateUrl: './maven-getting-started.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class MavenGettingStartedPageComponent implements OnInit {

  header = mavenText.GettingStarted.header;
  steps: Step[] = mavenText.GettingStarted.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {MavenGettingStartedPageComponent};
