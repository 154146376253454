import {Component, OnInit} from '@angular/core';
import postgresText from '../../../../../../assets/js/tutorial-texts/PostgresTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-postgres-views',
  templateUrl: './postgres-views.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class PostGresViewsPageComponent implements OnInit {

  header = postgresText.Views.header;
  steps: Step[] = postgresText.Views.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {PostGresViewsPageComponent};
