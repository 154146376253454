import {Component, OnInit} from '@angular/core';
import cssText from '../../../../../../assets/js/tutorial-texts/CssTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-css-transitions',
  templateUrl: './css-transitions.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class CssTransitionsPageComponent implements OnInit {

  header = cssText.Transitions.header;
  steps: Step[] = cssText.Transitions.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {CssTransitionsPageComponent};
