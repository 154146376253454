import {Component, OnInit} from '@angular/core';
import postgresText from '../../../../../../assets/js/tutorial-texts/PostgresTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-postgres-procedures',
  templateUrl: './postgres-procedures.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class PostGresProceduresPageComponent implements OnInit {

  header = postgresText.Procedures.header;
  steps: Step[] = postgresText.Procedures.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {PostGresProceduresPageComponent};
