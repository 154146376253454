import {Component, OnInit} from '@angular/core';
import mongoText from '../../../../../../assets/js/tutorial-texts/MongoTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-mongo-getting-started',
  templateUrl: './mongo-getting-started.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class MongoGettingStartedPageComponent implements OnInit {

  header = mongoText.GettingStarted.header;
  steps: Step[] = mongoText.GettingStarted.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {MongoGettingStartedPageComponent};
