import {Component, HostListener, OnInit} from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'app-resume-page',
  templateUrl: './resume-page.component.html',
  styleUrls: ['./resume-page.component.less'],
})
class ResumePageComponent implements OnInit {

  html = document.documentElement;
  frameCount = 1071;
  image = new Image();
  context: CanvasRenderingContext2D = undefined as any as CanvasRenderingContext2D;
  scrollLasVegasImgSrc = this.currentFrame(1);
  allImagesLoaded = false;

  ngOnInit(): void {
    Promise.all(Array.from(document.images)
    .filter(img => !img.complete)
    .map(img => new Promise(resolve => { img.onload = img.onerror = resolve; })))
    .then(() => {
      console.log('images finished loading');
      this.allImagesLoaded = true;
    });
  }

  currentFrame(index: number): string {
    return './assets/images/scroll/lasvegas/' + index.toString().padStart(3, '0') + '.jpg';
  }

  updateImage(index: number): void {
    this.scrollLasVegasImgSrc = this.currentFrame(index + 1);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    const scrollTop = this.html.scrollTop;
    const maxScrollTop = this.html.scrollHeight - window.innerHeight;
    const scrollFraction = scrollTop / maxScrollTop;
    const frameIndex = Math.min(this.frameCount - 1, Math.floor(scrollFraction * this.frameCount));
    requestAnimationFrame(() => this.updateImage(frameIndex));
  }
}

export { ResumePageComponent };
