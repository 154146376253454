<app-main-header [inverseHeaderColor]="inverseHeaderColor"></app-main-header>
<div style="opacity: 0">
  <div class="non-showing-images" *ngFor="let item of [].constructor(frameCount); let i = index">
    <img style="position: absolute; top: 0" loading="lazy" src="./assets/images/scroll/newyork/{{(i+1).toString().padStart(3, '0')}}.jpg" alt="">
  </div>
</div>
<div class="row no-margins services-body-scroll" (scroll)="onScroll()">
  <div class="col-xs-12 services-body-scroll-canvas no-padding">
    <img *ngIf="!allImagesLoaded" loading="lazy" src="./assets/images/scroll/newyork/001.jpg" alt="">
    <img *ngIf="allImagesLoaded" loading="lazy" src="{{scrollNewYorkImgSrc}}" alt="">
  </div>
</div>

<div class="row no-margins services-body-1">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <div class="services-title-box">
          <h1>NEED A WEBSITE</h1>
          <p>Need a website?</p>
          <p>Let me help you get started.</p>
          <p>I create websites for both private use and organizations.</p>
          <p>Scroll down and have a look at the different offers I have.</p>
          <p>I'm sure you'll find the perfect use case that suits you the most.</p>
          <p>Down below you will find different offers that can suite your needs.</p>
          <p>If you have any questions don’t hesitate to contact me.</p>
        </div>
      </div>
      <div class="col-xs-6"></div>
    </div>
  </div>
</div>

<div class="row no-margins services-body-2">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-6"></div>
      <div class="col-sm-4">
        <div id="EasyPackage" class="services-title-box">
          <h1 style="-webkit-text-stroke: 1px black;">Go</h1>
          <h1 style="color: black; position: relative;top: -20px;">Easy</h1>
          <p class="black">A website that defines as easy is a website where you don't need anything more advance than a simple information website.</p>
          <p class="black">A great example of this is actually the very website you're visiting right now.</p>
        </div>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</div>

<div class="row no-margins services-body-3">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <div id="BetterPackage" class="services-title-box">
          <h1>Go</h1>
          <h1 style="position: relative;top: -20px;">Better</h1>
          <p>A website that defines as better is a website where you get a so-called BackEnd system.</p>
          <p>The BackEnd system allows you to improve your website.</p>
          <p>For instance, you may want to connect your website into any payment methods.</p>
          <p>But we can do a lot more with the BackEnd system tool.</p>
        </div>
      </div>
      <div class="col-xs-6"></div>
    </div>
  </div>
</div>

<div class="row no-margins services-body-4">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-6"></div>
      <div class="col-sm-4">
        <div id="AdvancedPackage" class="services-title-box">
          <h1 style="-webkit-text-stroke: 1px black;">Go</h1>
          <h1 style="color: black; position: relative;top: -20px;">Advanced</h1>
          <p class="black">A website that defines as advanced is a website where you get the BackEnd system and a Database connected to it.</p>
          <p class="black">This allows you to save state on your website live. For instance by having any customer being able to save reviews directly on your website.</p>
          <p class="black">Or if you need clients to be able to login to your website.</p>
        </div>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</div>

<div class="row no-margins services-body-5">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <div id="FullScalePackage" class="services-title-box">
          <h1>Go</h1>
          <h1 style="position: relative;top: -20px;">Full Scale</h1>
          <p>A website that defines as full scale is basically basically an E-commerce solution. A website where you get the BackEnd system, a Database connected to it and an Admin Graphical User Interface.</p>
          <p>This means that you can log in to your own website as admin and have control on the functionality of your website.</p>
          <p>For instance, if you need to add Today´s Deal on a product or change the price of another.</p>
        </div>
      </div>
      <div class="col-xs-6"></div>
    </div>
  </div>
</div>

<div class="row no-margins services-body-6">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-2"></div>
      <div class="col-sm-8">
        <div class="row">
          <div class="col-sm-4">
            <div class="services-title-box">
              <h1 class="black">Prices</h1>
              <p class="black" style="font-weight: initial">900 SEK / hour</p>
              <p class="black">Additional Monthly Fees for maintenance if any</p>
              <p class="black">Additional Annual Fees for keeping you DNS (website url)</p>
              <p class="black">Additional Fees for support if website structural change is needed by request from customer</p>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="services-title-box">
              <h1 style="-webkit-text-stroke: 1px black;">Time</h1>
              <p class="black">First step is to contact me by email <a href="Contact">here</a>.</p>
              <p class="black">Then I'll be in touch with you for a meeting online or face to face.</p>
              <p class="black">We will go through your request and set up the perfect way to get your expected results as fast as possible.</p>
              <p class="black">When we are satisfied you will be noted with an invoice.</p>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="services-title-box">
              <h1 class="black">Support</h1>
              <p class="black">A first line support is of course by me and I will be serving you as fast as I can.</p>
              <p class="black">Be sure to read the <a href="IntegrityPolicy">Integrity Policy</a> and <a href="TermsAndConditions">Terms & Conditions</a>.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</div>
