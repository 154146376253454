import {Component, OnInit} from '@angular/core';
import javascriptText from '../../../../../../assets/js/tutorial-texts/JavascriptTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-javascript-lesson-2',
  templateUrl: './javascript-lesson-2.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class JavascriptLesson2PageComponent implements OnInit {

  header = javascriptText.Lesson2.header;
  steps: Step[] = javascriptText.Lesson2.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {JavascriptLesson2PageComponent};
