import { Component } from '@angular/core';

@Component({
  selector: 'app-art-page',
  templateUrl: './art-page.component.html',
  styleUrls: ['./art-page.component.less']
})
class ArtPageComponent {

}

export { ArtPageComponent };
