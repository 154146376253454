import { Component, OnInit } from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'app-portfolio-page',
  templateUrl: './portfolio-page.component.html',
  styleUrls: ['./portfolio-page.component.less'],
})
class PortfolioPageComponent implements OnInit {

  ngOnInit(): void {
    $('body')[0].style.overflowY = 'scroll';
  }
}

export { PortfolioPageComponent };
