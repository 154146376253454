import {Component, OnInit} from '@angular/core';
import javascriptText from '../../../../../../assets/js/tutorial-texts/JavascriptTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-javascript-getting-started',
  templateUrl: './javascript-getting-started.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class JavascriptGettingStartedPageComponent implements OnInit {

  header = javascriptText.GettingStarted.header;
  steps: Step[] = javascriptText.GettingStarted.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {JavascriptGettingStartedPageComponent};
