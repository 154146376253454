import {Component, OnInit} from '@angular/core';
import {Step} from '../../../common/common-tutorial-page.component';
import angularText from '../../../../../../assets/js/tutorial-texts/AngularTexts.json';

@Component({
  selector: 'app-angular-lesson-1',
  templateUrl: './angular-lesson-1.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class AngularLesson1PageComponent implements OnInit {

  header = angularText.Lesson1.header;
  steps: Step[] = angularText.Lesson1.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {AngularLesson1PageComponent};
