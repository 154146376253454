import {Component, OnInit} from '@angular/core';
import javaText from '../../../../../../assets/js/tutorial-texts/JavaTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-java-lesson-20',
  templateUrl: './java-lesson-20.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class JavaLesson20PageComponent implements OnInit {

  header = javaText.Lesson20.header;
  steps: Step[] = javaText.Lesson20.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {JavaLesson20PageComponent};
