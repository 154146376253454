import {Component, OnInit} from '@angular/core';
import mysqlText from '../../../../../../assets/js/tutorial-texts/MysqlTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-mysql-joins',
  templateUrl: './mysql-joins.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class MySQLJoinsPageComponent implements OnInit {

  header = mysqlText.Joins.header;
  steps: Step[] = mysqlText.Joins.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {MySQLJoinsPageComponent};
