<app-main-header></app-main-header>
<div class="row no-margins home-body-1">
  <div class="col-xs-12 no-padding" style="max-height: 1175px; overflow-y: hidden;">
    <div class="home-background-image">
      <img src="./assets/images/background/MainBackground.png" alt="">
    </div>
    <div class="home-background-image-logo animated fadeIn slow">
      <img *ngIf="!gifDone" src="./assets/images/Logos/Gabriel_Eriksson Transparent.gif" alt="">
      <img *ngIf="gifDone" src="./assets/images/Logos/Gabriel-Eriksson_white_low-res.png" alt="">
    </div>
  </div>
</div>

<div class="row no-margins home-body-2">
  <div class="col-xs-12 no-padding">
    <img class="home-body-image" src="./assets/images/scroll/tokyo/100.jpg" alt="">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <div class="home-title-box">
          <h1>ABOUT</h1>
          <h1 style="position: relative;top: -20px;">GABRIEL</h1>
          <p>Have a look at who I am.</p>
          <a href="About">Enter</a>
        </div>
      </div>
      <div class="col-xs-6"></div>
    </div>
  </div>
</div>

<div class="row no-margins home-body-3">
  <div class="col-xs-12">
    <img class="home-body-image" src="./assets/images/scroll/tokyo/600.jpg" alt="">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-6"></div>
      <div class="col-sm-4">
        <div class="home-title-box">
          <h1 style="-webkit-text-stroke: 1px black;">SEE</h1>
          <h1 style="color: black; position: relative;top: -20px;">GALLERY</h1>
          <p>As for my interests other than programming, see what else I like to do.</p>
          <a href="Gallery">Enter</a>
        </div>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</div>

<div class="row no-margins home-body-4">
  <img class="home-body-image" src="./assets/images/scroll/tokyo/900.jpg" alt="">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <div class="home-title-box">
          <h1>Explore</h1>
          <h1 style="position: relative;top: -20px;">SERVICES</h1>
          <p>See the packages I offer about creating websites.</p>
          <a href="Services">Enter</a>
        </div>
      </div>
      <div class="col-xs-6"></div>
    </div>
  </div>
</div>

<div class="row no-margins home-body-5">
  <img class="home-body-image" src="./assets/images/scroll/newyork/100.jpg" alt="">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-6"></div>
      <div class="col-sm-4">
        <div class="home-title-box">
          <h1 style="-webkit-text-stroke: 1px black;">PORTFOLIO</h1>
          <h1 style="color: black; position: relative;top: -20px;">& RESUME</h1>
          <p>Please browse my previous work and resumes.</p>
          <a href="Portfolio">Enter</a>
        </div>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</div>

<div class="row no-margins home-body-6">
  <img class="home-body-image" src="./assets/images/scroll/newyork/600.jpg" alt="">
  <div class="col-xs-12">
    <div class="row no-margins animated fadeIn slow">
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <div class="home-title-box">
          <h1>LEARN WITH</h1>
          <h1 style="position: relative;top: -20px;">TUTORIALS</h1>
          <p>Enter my code chamber and see my learnings through out the years.</p>
          <p>Maybe you can learn something too!</p>
          <a href="Tutorials">Enter</a>
        </div>
      </div>
      <div class="col-xs-6"></div>
    </div>
  </div>
</div>

