import {Component, OnInit} from '@angular/core';
import cssText from '../../../../../../assets/js/tutorial-texts/CssTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-css-header',
  templateUrl: './css-header.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class CssHeaderPageComponent implements OnInit {

  header = cssText.Header.header;
  steps: Step[] = cssText.Header.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {CssHeaderPageComponent};
