<app-main-header></app-main-header>
<div class="row no-margins integrity-body animated fadeIn slow">
  <div class="col-xs-0 col-sm-3 no-padding"></div>
  <div class="col-xs-12 col-sm-6 no-padding">
    <div class="information-box">
      <h1 class="black">Integrity Policy</h1>
      <p class="bold black">About GE Websites and this privacy policy.</p>
      <p class="black">
        GE Websites in Stockholm (??????-????), (<span class="bold">"GE Websites"</span>, <span class="bold">"we"</span>, <span class="bold">"us"</span>, <span class="bold">"our"</span>) provides a service where customers can
        get a website created for them.
      </p>
      <br>
      <p class="black">
        Within the framework of GE Websites operations, GE Websites processes personal data for which GE Websites
        is responsible for personal data. This means that GE Websites has an obligation to ensure that the processing
        of personal data is carried out in accordance with applicable legislation and the principles set out in
        this privacy policy.
      </p>
      <br>
      <p class="black">
        It is important to us that you feel safe with what personal data we collect and above all
        how we treat them. This privacy policy therefore contains information about our handling of your data
        personal data, which is why it is important that you read and understand the privacy policy before using
        Our Services. If you don't feel comfortable with how we process your personal data, you shouldn't
        use our services.
      </p>
      <br>
      <p class="black">
        If you have any questions about our processing of your personal data, please contact us
        via the contact details below.
      </p>
      <br>
      <p class="black">
        Please note that some pages on our website may contain links to websites managed by
        Third Party. These websites have their own privacy policies and GE Websites is not responsible for their
        operations or their information routines. Users who send information to or through
        these third-party websites should therefore review the privacy policies of those websites before any
        personal data is sent to them.
      </p>
      <br>
      <p class="bold black">
        Transfer of personal data
      </p>
      <p class="black">
        GE Websites takes care when transferring your personal data. We want to emphasize that we do not
        sell your personal data to a third party. And your details will only be on gabrieleriksson.com if an agreement to this is placed.
      </p>
      <br>
      <p class="bold black">
        Changes to the privacy policy
      </p>
      <p class="black">
        Please note that the content of the privacy policy may change. The new version comes in
        such cases to be published on this page.
      </p>
      <br>
      <p class="black">
        If the changes concern such personal data processing that we carry out with the support of your consent will
        we to give you the opportunity to give your consent again.
      </p>
      <br>
      <p class="bold black">
        Contact us
      </p>
      <p class="black">
        If you have questions related to this integrity policy, if you suspect that a crime may have occurred
        against this integrity policy or if you wish to contact us for any reason set out herein
        integrity policy, please contact us <a href="Contact">here</a>.
      </p>
    </div>
  </div>
  <div class="col-xs-0 col-sm-3 no-padding"></div>
</div>
