<div class="blur-window"></div>
<div class="row no-margins code-small-header-body" style="overflow-x: hidden;overflow-y: hidden;">
  <div class="col-sm-12 no-padding">
    <div class="code-small-header">
      <a href="/" style="display: inline-flex;padding-left: 20%;">
        <div class="code-header-logo">
          <img src="./assets/images/Logos/Gabriel-Eriksson_black_low-res.png" alt="">
        </div>
      </a>
      <nav class="code-small-header-nav">
        <div class="dropdown-toggle code-small-header-hamburger">
          <a *ngIf="showSmallInnerHeader || showSmallInnerInnerHeader" class="nav-back-button" (click)="goBack();">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
          </a>
          <a class="nav-toggle" (click)="toggleHamburger(); toggleSmallHeader()">
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
          </a>
        </div>
      </nav>
    </div>
    <ul *ngIf="showSmallHeader" class="dropdown code-small-header-ul">
      <div class="animated fadeIn faster">
        <a href="/">
          <li><p>Home</p></li>
        </a>
        <a (click)="changeCurrent('frontend'); showInnerHeader()">
          <li><p>FrontEnd</p></li>
        </a>
        <a (click)="changeCurrent('backend'); showInnerHeader()">
          <li><p>BackEnd</p></li>
        </a>
        <a (click)="changeCurrent('database'); showInnerHeader()">
          <li><p>Database</p></li>
        </a>
        <a (click)="changeCurrent('devops'); showInnerHeader()">
          <li><p>DevOps</p></li>
        </a>
        <a (click)="changeCurrent('setup'); showInnerHeader()">
          <li><p>Setup</p></li>
        </a>
        <a (click)="changeCurrent('test'); showInnerHeader()">
          <li><p>Test</p></li>
        </a>
      </div>
    </ul>

    <ul *ngIf="showSmallInnerHeader" class="dropdown code-small-header-ul">
      <div class="col-xs-12 animated fadeIn faster">
        <a (click)="showInnerInnerHeader(0)">
          <li *ngIf="dropDownColumns[0] !== null"><p class="black">{{dropDownColumns[0]}}</p></li>
        </a>
        <a (click)="showInnerInnerHeader(1)">
          <li *ngIf="dropDownColumns[1] !== null"><p class="black">{{dropDownColumns[1]}}</p></li>
        </a>
        <a (click)="showInnerInnerHeader(2)">
          <li *ngIf="dropDownColumns[2] !== null"><p class="black">{{dropDownColumns[2]}}</p></li>
        </a>
        <a (click)="showInnerInnerHeader(3)">
          <li *ngIf="dropDownColumns[3] !== null"><p class="black">{{dropDownColumns[3]}}</p></li>
        </a>
        <a (click)="showInnerInnerHeader(4)">
          <li *ngIf="dropDownColumns[4] !== null"><p class="black">{{dropDownColumns[4]}}</p></li>
        </a>
        <a (click)="showInnerInnerHeader(5)">
          <li *ngIf="dropDownColumns[5] !== null"><p class="black">{{dropDownColumns[5]}}</p></li>
        </a>
      </div>
    </ul>

    <ul *ngIf="showSmallInnerInnerHeader" class="dropdown code-small-header-ul code-small-header-ul-inner-inner">
      <div class="col-xs-12 animated fadeIn faster">
        <a *ngFor="let link of dropDownColumnLinks[currentHeaderIndex]" (click)="goTo(link)" style="display: block">
          <li><p class="black">{{link.header}}</p></li>
        </a>
      </div>
    </ul>
  </div>
</div>

<div class="code-header animated fadeInDown">
  <div class="row no-margins" id="codeHeader">
    <div class="col-sm-12 code-header-inner" (mouseleave)="foldHeader()">
      <div class="row">
        <div class="col-sm-6 no-padding">
          <a href="/">
            <div class="code-header-logo">
              <img src="./assets/images/Logos/Gabriel-Eriksson_black_low-res.png" alt="">
            </div>
          </a>
        </div>
        <div class="col-sm-6 no-padding code-header-links">
          <div class="code-header-navbar">
            <ul class="code-header-navbar-ul no-padding">
              <a href="/" [ngClass]="highLightedHeader === '' ? 'header-highlighted' : ''">
                <li><p>Home</p></li>
              </a>
              <a href="Tutorials" [ngClass]="highLightedHeader === 'FrontEnd' ? 'header-highlighted' : ''" (mouseenter)="changeCurrent('frontend'); dropDownHeader('frontend')">
                <li><p>FrontEnd</p></li>
              </a>
              <a href="Tutorials" [ngClass]="highLightedHeader === 'BackEnd' ? 'header-highlighted' : ''" (mouseenter)="changeCurrent('backend'); dropDownHeader('backend')">
                <li><p>BackEnd</p></li>
              </a>
              <a href="Tutorials" [ngClass]="highLightedHeader === 'Database' ? 'header-highlighted' : ''" (mouseenter)="changeCurrent('database'); dropDownHeader('database')">
                <li><p>Database</p></li>
              </a>
              <a href="Tutorials" [ngClass]="highLightedHeader === 'DevOps' ? 'header-highlighted' : ''" (mouseenter)="changeCurrent('devops'); dropDownHeader('devops')">
                <li><p>DevOps</p></li>
              </a>
              <a href="Tutorials" [ngClass]="highLightedHeader === 'Setup' ? 'header-highlighted' : ''" (mouseenter)="changeCurrent('setup'); dropDownHeader('setup')">
                <li><p>Setup</p></li>
              </a>
              <a href="Tutorials" [ngClass]="highLightedHeader === 'Test' ? 'header-highlighted' : ''" (mouseenter)="changeCurrent('test'); dropDownHeader('test')">
                <li><p>Test</p></li>
              </a>
            </ul>
          </div>
        </div>
        <div *ngIf="showDropDown" class="row no-margins drop-down-tutorials">
          <div class="col-xs-2 no-padding"></div>
          <div class="col-xs-8 no-padding">
            <div class="row no-margins">
              <div *ngIf="dropDownColumns[0] !== null" class="col-xs-2">
                <h5 class="grey">{{dropDownColumns[0]}}</h5>
                <a class="darkgrey" *ngFor="let link of dropDownColumnLinks[0]" (click)="goTo(link)" style="display: block">{{link.header}}</a>
              </div>
              <div *ngIf="dropDownColumns[1] !== null" class="col-xs-2">
                <h5 class="grey">{{dropDownColumns[1]}}</h5>
                <a class="darkgrey" *ngFor="let link of dropDownColumnLinks[1]" (click)="goTo(link)" style="display: block">{{link.header}}</a>
              </div>
              <div *ngIf="dropDownColumns[2] !== null" class="col-xs-2">
                <h5 class="grey">{{dropDownColumns[2]}}</h5>
                <a class="darkgrey" *ngFor="let link of dropDownColumnLinks[2]" (click)="goTo(link)" style="display: block">{{link.header}}</a>
              </div>
              <div *ngIf="dropDownColumns[3] !== null" class="col-xs-2">
                <h5 class="grey">{{dropDownColumns[3]}}</h5>
                <a class="darkgrey" *ngFor="let link of dropDownColumnLinks[3]" (click)="goTo(link)" style="display: block">{{link.header}}</a>
              </div>
              <div *ngIf="dropDownColumns[4] !== null" class="col-xs-2">
                <h5 class="grey">{{dropDownColumns[4]}}</h5>
                <a class="darkgrey" *ngFor="let link of dropDownColumnLinks[4]" (click)="goTo(link)" style="display: block">{{link.header}}</a>
              </div>
              <div *ngIf="dropDownColumns[5] !== null" class="col-xs-2">
                <h5 class="grey">{{dropDownColumns[5]}}</h5>
                <a class="darkgrey" *ngFor="let link of dropDownColumnLinks[5]" (click)="goTo(link)" style="display: block">{{link.header}}</a>
              </div>
            </div>
          </div>
          <div class="col-xs-2 no-padding"></div>
        </div>
      </div>
    </div>
  </div>
</div>
