import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-tutorials-page',
  templateUrl: './tutorials-page.component.html',
  styleUrls: ['../tutorial.component.less']
})
class TutorialsPageComponent implements OnInit {

  ngOnInit(): void {
    $('body')[0].style.overflowY = 'scroll';
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {TutorialsPageComponent};
