<app-main-header></app-main-header>
<div class="resume-button">
  <a href="Resume">
    <button class="btn btn-default rotate-resume-button">RESUME</button>
  </a>
</div>
<div class="row no-margins portfolio-body">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 no-padding portfolio-body-1">
    <div class="portfolio-background-image">
      <img src="./assets/images/background/CodeBackground.jpg" alt="">
    </div>
    <div class="row">
      <div class="col-xs-0 col-sm-3"></div>
      <div class="col-xs-12 col-sm-6">
        <div class="portfolio-card animated fadeInUp fast">
          <div class="portfolio-image">
            <img src="./assets/images/portfolio/dinbliq.png" alt="">
          </div>
          <div class="row portfolio-card-info">
            <div class="col-sm-4 portfolio-card-info-head">
              <h1>BLIQ</h1>
              <a href="https://www.dinbliq.se/#!/" target="_blank">https://dinbliq.se</a>
              <p>Sofie Eriksson</p>
              <p style="line-height: .2">Beauty Specialist</p>
              <a href="Services#BetterPackage">Better Package</a>
            </div>
            <div class="col-sm-8 portfolio-card-info-foot">
              <div class="inline-flex">
                <h3>BLIQ</h3>
                <p style="position: relative; top: 25px; left: 5px;"> specializes in Lashes & Brows.</p>
              </div>
              <p>She has all kinds of treatments from Lash Lifts to Brow Tattoos.</p>
              <p>Be sure to check out her website for more information.</p>
              <p>And while you're there, why not treat yourself with a touch up.</p>
              <p>You deserve it!</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-0 col-sm-3"></div>
    </div>
  </div>
</div>
