import {Component, Input} from '@angular/core';

export interface Step {
  hasImages: boolean;
  imageSources: string[];
  header: string;
  informationTexts: string[];
}

@Component({
  selector: 'app-common-tutorial',
  templateUrl: './common-tutorial-page.component.html',
  styleUrls: ['../../../tutorial.component.less']
})
class CommonTutorialPageComponent {

  @Input() header = '';
  @Input() steps: Step[] = [];


  constructor() {
  }
}

export {CommonTutorialPageComponent};
