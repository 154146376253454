import {Component, OnInit} from '@angular/core';
import springText from '../../../../../../assets/js/tutorial-texts/SpringTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-spring-boot',
  templateUrl: './spring-boot.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class SpringbootPageComponent implements OnInit {

  header = springText.Boot.header;
  steps: Step[] = springText.Boot.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {SpringbootPageComponent};
