import {Component, OnInit} from '@angular/core';
import springText from '../../../../../../assets/js/tutorial-texts/SpringTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-spring-swagger',
  templateUrl: './spring-swagger.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class SpringSwaggerPageComponent implements OnInit {

  header = springText.Swagger.header;
  steps: Step[] = springText.Swagger.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {SpringSwaggerPageComponent};
