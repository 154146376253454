import {Component, OnInit} from '@angular/core';
import javaText from '../../../../../../assets/js/tutorial-texts/JavaTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-java-lesson-13',
  templateUrl: './java-lesson-13.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class JavaLesson13PageComponent implements OnInit {

  header = javaText.Lesson13.header;
  steps: Step[] = javaText.Lesson13.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {JavaLesson13PageComponent};
