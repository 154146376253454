<div class="row no-margins small-header-body" style="overflow-x: hidden;overflow-y: hidden;">
  <div class="col-sm-12 no-padding" style="backdrop-filter: blur(5px); -webkit-backdrop-filter: blur(5px);">
    <div class="small-header">
      <a href="/" style="display: inline-flex;padding-left: 20%;">
        <div class="main-header-logo">
          <img *ngIf="!inverseHeaderColor" src="./assets/images/Logos/Gabriel-Eriksson_white_low-res.png" alt="">
          <img *ngIf="inverseHeaderColor" src="./assets/images/Logos/Gabriel-Eriksson_black_low-res.png" alt="">
        </div>
      </a>
      <nav class="small-header-nav">
        <div class="dropdown-toggle small-header-hamburger">
          <a class="nav-toggle" (click)="toggleHamburger(); toggleSmallHeader()">
            <span class="bar" [ngClass]="inverseHeaderColor ? 'nav-toggle-background-color-black' : 'nav-toggle-background-color-white'"></span>
            <span class="bar" [ngClass]="inverseHeaderColor ? 'nav-toggle-background-color-black' : 'nav-toggle-background-color-white'"></span>
            <span class="bar" [ngClass]="inverseHeaderColor ? 'nav-toggle-background-color-black' : 'nav-toggle-background-color-white'"></span>
          </a>
        </div>
      </nav>
    </div>
    <ul *ngIf="showSmallHeader" class="dropdown small-header-ul">
      <a href="/">
        <li><p>Home</p></li>
      </a>
      <a href="About">
        <li><p>About</p></li>
      </a>
      <a href="Gallery">
        <li><p>Gallery</p></li>
      </a>
      <a href="Services">
        <li><p>Services</p></li>
      </a>
      <a href="Portfolio">
        <li><p>Portfolio</p></li>
      </a>
      <a href="Contact">
        <li><p>Contact</p></li>
      </a>
      <a href="Tutorials">
        <li><p>Tutorials</p></li>
      </a>
    </ul>
  </div>
</div>

<div class="main-header animated fadeInDown">
  <div class="row no-margins" id="mainHeader">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-6 no-padding">
          <a href="/">
            <div class="main-header-logo">
              <img *ngIf="!inverseHeaderColor" src="./assets/images/Logos/Gabriel-Eriksson_white_low-res.png" alt="">
              <img *ngIf="inverseHeaderColor" src="./assets/images/Logos/Gabriel-Eriksson_black_low-res.png" alt="">
            </div>
          </a>
        </div>
        <div class="col-sm-6 no-padding main-header-links">
          <div class="main-header-navbar">
            <ul class="main-header-navbar-ul no-padding" [ngClass]="inverseHeaderColor ? 'main-header-color-black': 'main-header-color-white'">
              <a id="HeaderHome" href="/" [ngClass]="highLightedHeader === '' ? 'header-highlighted' : ''" (mouseover)="setCurrentHighlight('home')" (mouseleave)="setCurrentHighlightDefault()">
                <li><p>Home</p></li>
              </a>
              <a id="HeaderAbout" href="About" [ngClass]="highLightedHeader === 'About' ? 'header-highlighted' : ''" (mouseover)="setCurrentHighlight('about')" (mouseleave)="setCurrentHighlightDefault()">
                <li><p>About</p></li>
              </a>
              <a id="HeaderGallery" href="Gallery" [ngClass]="highLightedHeader === 'Gallery' ? 'header-highlighted' : ''" (mouseover)="setCurrentHighlight('gallery')" (mouseleave)="setCurrentHighlightDefault()">
                <li><p>Gallery</p></li>
              </a>
              <a id="HeaderServices" href="Services" [ngClass]="highLightedHeader === 'Services' ? 'header-highlighted' : ''" (mouseover)="setCurrentHighlight('services')" (mouseleave)="setCurrentHighlightDefault()">
                <li><p>Services</p></li>
              </a>
              <a id="HeaderPortfolio" href="Portfolio" [ngClass]="highLightedHeader === 'Portfolio' ? 'header-highlighted' : ''" (mouseover)="setCurrentHighlight('portfolio')" (mouseleave)="setCurrentHighlightDefault()">
                <li><p>Portfolio</p></li>
              </a>
              <a id="HeaderContact" href="Contact" [ngClass]="highLightedHeader === 'Contact' ? 'header-highlighted' : ''" (mouseover)="setCurrentHighlight('contact')" (mouseleave)="setCurrentHighlightDefault()">
                <li><p>Contact</p></li>
              </a>
              <a id="HeaderTutorials" href="Tutorials" [ngClass]="highLightedHeader === 'Tutorials' ? 'header-highlighted' : ''" (mouseover)="setCurrentHighlight('tutorials')" (mouseleave)="setCurrentHighlightDefault()">
                <li><p>Tutorials</p></li>
              </a>
              <span class="border-bottom-span"></span>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
