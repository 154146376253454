import {Component} from '@angular/core';

@Component({
  selector: 'app-gallery-page',
  templateUrl: './gallery-page.component.html',
  styleUrls: ['./gallery-page.component.less']
})
class GalleryPageComponent {
}

export { GalleryPageComponent };
