import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularGettingStartedPageComponent } from './tutorialspage/pages/angular/lessons/gettingstarted/angular-getting-started.component';
import { AngularLesson1PageComponent } from './tutorialspage/pages/angular/lessons/lesson1/angular-lesson-1.component';
import { AngularLesson2PageComponent } from './tutorialspage/pages/angular/lessons/lesson2/angular-lesson-2.component';
import { AngularLesson3PageComponent } from './tutorialspage/pages/angular/lessons/lesson3/angular-lesson-3.component';
import { AngularLesson4PageComponent } from './tutorialspage/pages/angular/lessons/lesson4/angular-lesson-4.component';
import { AngularLesson5PageComponent } from './tutorialspage/pages/angular/lessons/lesson5/angular-lesson-5.component';
import { AngularLesson6PageComponent } from './tutorialspage/pages/angular/lessons/lesson6/angular-lesson-6.component';
import { ArtPageComponent } from './artpage/art-page.component';
import { TutorialsPageComponent } from './tutorialspage/tutorials-page.component';
import { AboutPageComponent } from './aboutpage/about-page.component';
import { CssBasicPageComponent } from './tutorialspage/pages/css/lessons/basic/css-basic.component';
import { CssShadowsPageComponent } from './tutorialspage/pages/css/lessons/shadows/css-shadows.component';
import { CssAlignmentsPageComponent } from './tutorialspage/pages/css/lessons/alignments/css-alignments.component';
import { CssGradientsPageComponent } from './tutorialspage/pages/css/lessons/gradients/css-gradients.component';
import { CssResponsivenessPageComponent } from './tutorialspage/pages/css/lessons/responsiveness/css-responsiveness.component';
import { CssTransitionsPageComponent } from './tutorialspage/pages/css/lessons/transitions/css-transitions.component';
import { CssCarouselPageComponent } from './tutorialspage/pages/css/lessons/carousel/css-carousel.component';
import { CssHeaderPageComponent } from './tutorialspage/pages/css/lessons/header/css-header.component';
import { ElasticSearchWhatIsPageComponent } from './tutorialspage/pages/elastic/lessons/whatis/elastic-what-is.component';
import { ElasticSearchGettingStartedPageComponent } from './tutorialspage/pages/elastic/lessons/gettingstarted/elastic-getting-started.component';
import { ElasticSearchQueriesPageComponent } from './tutorialspage/pages/elastic/lessons/queries/elastic-queries.component';
import { HomePageComponent } from './homepage/home-page.component';
import { HtmlGettingStartedPageComponent } from './tutorialspage/pages/html/lessons/gettingstarted/html-getting-started.component';
import { HtmlElementsPageComponent } from './tutorialspage/pages/html/lessons/elements/html-elements.component';
import { HtmlBoostrapPageComponent } from './tutorialspage/pages/html/lessons/bootstrap/html-bootstrap.component';
import { JavaGettingStartedPageComponent } from './tutorialspage/pages/java/lessons/gettingstarted/java-getting-started.component';
import { JavaLesson1PageComponent } from './tutorialspage/pages/java/lessons/lesson1/java-lesson-1.component';
import { JavaLesson10PageComponent } from './tutorialspage/pages/java/lessons/lesson10/java-lesson-10.component';
import { JavaLesson11PageComponent } from './tutorialspage/pages/java/lessons/lesson11/java-lesson-11.component';
import { JavaLesson12PageComponent } from './tutorialspage/pages/java/lessons/lesson12/java-lesson-12.component';
import { JavaLesson13PageComponent } from './tutorialspage/pages/java/lessons/lesson13/java-lesson-13.component';
import { JavaLesson14PageComponent } from './tutorialspage/pages/java/lessons/lesson14/java-lesson-14.component';
import { JavaLesson15PageComponent } from './tutorialspage/pages/java/lessons/lesson15/java-lesson-15.component';
import { JavaLesson16PageComponent } from './tutorialspage/pages/java/lessons/lesson16/java-lesson-16.component';
import { JavaLesson17PageComponent } from './tutorialspage/pages/java/lessons/lesson17/java-lesson-17.component';
import { JavaLesson18PageComponent } from './tutorialspage/pages/java/lessons/lesson18/java-lesson-18.component';
import { JavaLesson19PageComponent } from './tutorialspage/pages/java/lessons/lesson19/java-lesson-19.component';
import { JavaLesson2PageComponent } from './tutorialspage/pages/java/lessons/lesson2/java-lesson-2.component';
import { JavaLesson20PageComponent } from './tutorialspage/pages/java/lessons/lesson20/java-lesson-20.component';
import { JavaLesson21PageComponent } from './tutorialspage/pages/java/lessons/lesson21/java-lesson-21.component';
import { JavaLesson22PageComponent } from './tutorialspage/pages/java/lessons/lesson22/java-lesson-22.component';
import { JavaLesson23PageComponent } from './tutorialspage/pages/java/lessons/lesson23/java-lesson-23.component';
import { JavaLesson3PageComponent } from './tutorialspage/pages/java/lessons/lesson3/java-lesson-3.component';
import { JavaLesson4PageComponent } from './tutorialspage/pages/java/lessons/lesson4/java-lesson-4.component';
import { JavaLesson5PageComponent } from './tutorialspage/pages/java/lessons/lesson5/java-lesson-5.component';
import { JavaLesson6PageComponent } from './tutorialspage/pages/java/lessons/lesson6/java-lesson-6.component';
import { JavaLesson7PageComponent } from './tutorialspage/pages/java/lessons/lesson7/java-lesson-7.component';
import { JavaLesson8PageComponent } from './tutorialspage/pages/java/lessons/lesson8/java-lesson-8.component';
import { JavaLesson9PageComponent } from './tutorialspage/pages/java/lessons/lesson9/java-lesson-9.component';
import { JavascriptGettingStartedPageComponent } from './tutorialspage/pages/javascript/lessons/gettingstarted/javascript-getting-started.component';
import { JavascriptLesson1PageComponent } from './tutorialspage/pages/javascript/lessons/lesson1/javascript-lesson-1.component';
import { JavascriptLesson2PageComponent } from './tutorialspage/pages/javascript/lessons/lesson2/javascript-lesson-2.component';
import { JavascriptLesson3PageComponent } from './tutorialspage/pages/javascript/lessons/lesson3/javascript-lesson-3.component';
import { JavascriptLesson4PageComponent } from './tutorialspage/pages/javascript/lessons/lesson4/javascript-lesson-4.component';
import { JavascriptLesson5PageComponent } from './tutorialspage/pages/javascript/lessons/lesson5/javascript-lesson-5.component';
import { JavascriptLesson6PageComponent } from './tutorialspage/pages/javascript/lessons/lesson6/javascript-lesson-6.component';
import { JavascriptLesson7PageComponent } from './tutorialspage/pages/javascript/lessons/lesson7/javascript-lesson-7.component';
import { JqueryGettingStartedPageComponent } from './tutorialspage/pages/jquery/lessons/gettingstarted/jquery-getting-started.component';
import { JqueryLesson1PageComponent } from './tutorialspage/pages/jquery/lessons/lesson1/jquery-lesson-1.component';
import { JqueryLesson2PageComponent } from './tutorialspage/pages/jquery/lessons/lesson2/jquery-lesson-2.component';
import { JqueryLesson3PageComponent } from './tutorialspage/pages/jquery/lessons/lesson3/jquery-lesson-3.component';
import { JqueryLesson4PageComponent } from './tutorialspage/pages/jquery/lessons/lesson4/jquery-lesson-4.component';
import { JqueryLesson5PageComponent } from './tutorialspage/pages/jquery/lessons/lesson5/jquery-lesson-5.component';
import { JqueryLesson6PageComponent } from './tutorialspage/pages/jquery/lessons/lesson6/jquery-lesson-6.component';
import { JqueryLesson7PageComponent } from './tutorialspage/pages/jquery/lessons/lesson7/jquery-lesson-7.component';
import { MavenDependenciesPageComponent } from './tutorialspage/pages/maven/lessons/dependencies/maven-dependencies.component';
import { MavenGettingStartedPageComponent } from './tutorialspage/pages/maven/lessons/gettingstarted/maven-getting-started.component';
import { MavenWhatIsPageComponent } from './tutorialspage/pages/maven/lessons/whatis/maven-what-is.component';
import { MongoWhatIsPageComponent } from './tutorialspage/pages/mongo/lessons/whatis/mongo-what-is.component';
import { MongoGettingStartedPageComponent } from './tutorialspage/pages/mongo/lessons/gettingstarted/mongo-getting-started.component';
import { MongoCollectionsPageComponent } from './tutorialspage/pages/mongo/lessons/collection/mongo-collection.component';
import { MongoQueriesPageComponent } from './tutorialspage/pages/mongo/lessons/queries/mongo-queries.component';
import { MusicPageComponent } from './musicpage/music-page.component';
import { MySQLWhatIsPageComponent } from './tutorialspage/pages/mysql/lessons/whatis/mysql-what-is.component';
import { MySQLGettingStartedPageComponent } from './tutorialspage/pages/mysql/lessons/gettingstarted/mysql-getting-started.component';
import { MySQLQueriesPageComponent } from './tutorialspage/pages/mysql/lessons/queries/mysql-queries.component';
import { MySQLAlterPageComponent } from './tutorialspage/pages/mysql/lessons/alter/mysql-alter.component';
import { MySQLJoinsPageComponent } from './tutorialspage/pages/mysql/lessons/joins/mysql-joins.component';
import { MySQLViewsPageComponent } from './tutorialspage/pages/mysql/lessons/views/mysql-views.component';
import { MySQLFunctionsPageComponent } from './tutorialspage/pages/mysql/lessons/functions/mysql-functions.component';
import { MySQLProceduresPageComponent } from './tutorialspage/pages/mysql/lessons/procedures/mysql-procedures.component';
import { PostGresWhatIsPageComponent } from './tutorialspage/pages/postgres/lessons/whatis/postgres-what-is.component';
// tslint:disable-next-line:max-line-length
import { PostGresGettingStartedPageComponent } from './tutorialspage/pages/postgres/lessons/gettingstarted/postgres-getting-started.component';
import { PostGresQueriesPageComponent } from './tutorialspage/pages/postgres/lessons/queries/postgres-queries.component';
import { PostGresAlterPageComponent } from './tutorialspage/pages/postgres/lessons/alter/postgres-alter.component';
import { PostGresJoinsPageComponent } from './tutorialspage/pages/postgres/lessons/joins/postgres-joins.component';
import { PostGresViewsPageComponent } from './tutorialspage/pages/postgres/lessons/views/postgres-views.component';
import { PostGresFunctionsPageComponent } from './tutorialspage/pages/postgres/lessons/functions/postgres-functions.component';
import { PostGresProceduresPageComponent } from './tutorialspage/pages/postgres/lessons/procedures/postgres-procedures.component';
import { PortfolioPageComponent } from './portfoliopage/portfolio-page.component';
import { SpringWhatIsPageComponent } from './tutorialspage/pages/spring/lessons/springwhatis/spring-what-is.component';
import { SpringAnnotationsPageComponent } from './tutorialspage/pages/spring/lessons/annotations/spring-annotations.component';
import { SpringbootPageComponent } from './tutorialspage/pages/spring/lessons/boot/spring-boot.component';
import { SpringSwaggerPageComponent } from './tutorialspage/pages/spring/lessons/swagger/spring-swagger.component';
import { SpringSecurityPageComponent } from './tutorialspage/pages/spring/lessons/security/spring-security.component';
import { SpringKafkaPageComponent } from './tutorialspage/pages/spring/lessons/kafka/spring-kafka.component';
import { SpringWebSocketsPageComponent } from './tutorialspage/pages/spring/lessons/websockets/spring-web-sockets.component';
import { GalleryPageComponent } from './gallerypage/gallery-page.component';
import { ServicesPageComponent } from './servicespage/services-page.component';
import { ContactPageComponent } from './contactpage/contact-page.component';
import { ResumePageComponent } from './resumepage/resume-page.component';
import { IntegrityPageComponent } from './integritypolicypage/integrity-page.component';
import { TermsAndConditionsPageComponent } from './termsandconditionspage/terms-and-conditions-page.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'About', component: AboutPageComponent },
  { path: 'Gallery', component: GalleryPageComponent },
  { path: 'Art', component: ArtPageComponent },
  { path: 'Music', component: MusicPageComponent },
  { path: 'Services', component: ServicesPageComponent },
  { path: 'Portfolio', component: PortfolioPageComponent },
  { path: 'Resume', component: ResumePageComponent, },
  { path: 'Tutorials', component: TutorialsPageComponent },
  { path: 'Contact', component: ContactPageComponent },
  { path: 'IntegrityPolicy', component: IntegrityPageComponent },
  { path: 'TermsAndConditions', component: TermsAndConditionsPageComponent },
  { path: 'angular/basic/GettingStarted', component: AngularGettingStartedPageComponent },
  { path: 'angular/basic/Lesson1', component: AngularLesson1PageComponent },
  { path: 'angular/basic/Lesson2', component: AngularLesson2PageComponent },
  { path: 'angular/basic/Lesson3', component: AngularLesson3PageComponent },
  { path: 'angular/basic/Lesson4', component: AngularLesson4PageComponent },
  { path: 'angular/basic/Lesson5', component: AngularLesson5PageComponent },
  { path: 'angular/basic/Lesson6', component: AngularLesson6PageComponent },
  { path: 'css/basic/BasicStyling', component: CssBasicPageComponent },
  { path: 'css/basic/Shadows', component: CssShadowsPageComponent },
  { path: 'css/basic/Alignments', component: CssAlignmentsPageComponent },
  { path: 'css/basic/Gradients', component: CssGradientsPageComponent },
  { path: 'css/basic/Responsiveness', component: CssResponsivenessPageComponent },
  { path: 'css/basic/Transitions', component: CssTransitionsPageComponent },
  { path: 'css/basic/Carousels', component: CssCarouselPageComponent },
  { path: 'css/basic/Headers', component: CssHeaderPageComponent },
  { path: 'elastic/basic/WhatIsElasticSearch', component: ElasticSearchWhatIsPageComponent },
  { path: 'elastic/basic/GettingStarted', component: ElasticSearchGettingStartedPageComponent },
  { path: 'elastic/basic/Queries', component: ElasticSearchQueriesPageComponent },
  { path: 'html/basic/GettingStarted', component: HtmlGettingStartedPageComponent },
  { path: 'html/basic/Elements', component: HtmlElementsPageComponent },
  { path: 'html/basic/Bootstrap', component: HtmlBoostrapPageComponent },
  { path: 'java/basic/GettingStarted', component: JavaGettingStartedPageComponent },
  { path: 'java/basic/Lesson1', component: JavaLesson1PageComponent },
  { path: 'java/basic/Lesson2', component: JavaLesson2PageComponent },
  { path: 'java/basic/Lesson3', component: JavaLesson3PageComponent },
  { path: 'java/basic/Lesson4', component: JavaLesson4PageComponent },
  { path: 'java/basic/Lesson5', component: JavaLesson5PageComponent },
  { path: 'java/basic/Lesson6', component: JavaLesson6PageComponent },
  { path: 'java/basic/Lesson7', component: JavaLesson7PageComponent },
  { path: 'java/basic/Lesson8', component: JavaLesson8PageComponent },
  { path: 'java/basic/Lesson9', component: JavaLesson9PageComponent },
  { path: 'java/basic/Lesson10', component: JavaLesson10PageComponent },
  { path: 'java/basic/Lesson11', component: JavaLesson11PageComponent },
  { path: 'java/basic/Lesson12', component: JavaLesson12PageComponent },
  { path: 'java/basic/Lesson13', component: JavaLesson13PageComponent },
  { path: 'java/basic/Lesson14', component: JavaLesson14PageComponent },
  { path: 'java/basic/Lesson15', component: JavaLesson15PageComponent },
  { path: 'java/basic/Lesson16', component: JavaLesson16PageComponent },
  { path: 'java/basic/Lesson17', component: JavaLesson17PageComponent },
  { path: 'java/basic/Lesson18', component: JavaLesson18PageComponent },
  { path: 'java/basic/Lesson19', component: JavaLesson19PageComponent },
  { path: 'java/basic/Lesson20', component: JavaLesson20PageComponent },
  { path: 'java/basic/Lesson21', component: JavaLesson21PageComponent },
  { path: 'java/basic/Lesson22', component: JavaLesson22PageComponent },
  { path: 'java/basic/Lesson23', component: JavaLesson23PageComponent },
  { path: 'javascript/basic/GettingStarted', component: JavascriptGettingStartedPageComponent },
  { path: 'javascript/basic/Lesson1', component: JavascriptLesson1PageComponent },
  { path: 'javascript/basic/Lesson2', component: JavascriptLesson2PageComponent },
  { path: 'javascript/basic/Lesson3', component: JavascriptLesson3PageComponent },
  { path: 'javascript/basic/Lesson4', component: JavascriptLesson4PageComponent },
  { path: 'javascript/basic/Lesson5', component: JavascriptLesson5PageComponent },
  { path: 'javascript/basic/Lesson6', component: JavascriptLesson6PageComponent },
  { path: 'javascript/basic/Lesson7', component: JavascriptLesson7PageComponent },
  { path: 'jquery/basic/GettingStarted', component: JqueryGettingStartedPageComponent },
  { path: 'jquery/basic/Lesson1', component: JqueryLesson1PageComponent },
  { path: 'jquery/basic/Lesson2', component: JqueryLesson2PageComponent },
  { path: 'jquery/basic/Lesson3', component: JqueryLesson3PageComponent },
  { path: 'jquery/basic/Lesson4', component: JqueryLesson4PageComponent },
  { path: 'jquery/basic/Lesson5', component: JqueryLesson5PageComponent },
  { path: 'jquery/basic/Lesson6', component: JqueryLesson6PageComponent },
  { path: 'jquery/basic/Lesson7', component: JqueryLesson7PageComponent },
  { path: 'maven/WhatIsMaven', component: MavenWhatIsPageComponent },
  { path: 'maven/GettingStarted', component: MavenGettingStartedPageComponent },
  { path: 'maven/Dependencies', component: MavenDependenciesPageComponent },
  { path: 'mongo/WhatIsMongo', component: MongoWhatIsPageComponent },
  { path: 'mongo/GettingStarted', component: MongoGettingStartedPageComponent },
  { path: 'mongo/Collections', component: MongoCollectionsPageComponent },
  { path: 'mongo/Queries', component: MongoQueriesPageComponent },
  { path: 'mysql/WhatIsMySQL', component: MySQLWhatIsPageComponent },
  { path: 'mysql/GettingStarted', component: MySQLGettingStartedPageComponent },
  { path: 'mysql/Queries', component: MySQLQueriesPageComponent },
  { path: 'mysql/Alter', component: MySQLAlterPageComponent },
  { path: 'mysql/Joins', component: MySQLJoinsPageComponent },
  { path: 'mysql/Views', component: MySQLViewsPageComponent },
  { path: 'mysql/Functions', component: MySQLFunctionsPageComponent },
  { path: 'mysql/Procedures', component: MySQLProceduresPageComponent },
  { path: 'postgres/WhatIsMySQL', component: PostGresWhatIsPageComponent },
  { path: 'postgres/GettingStarted', component: PostGresGettingStartedPageComponent },
  { path: 'postgres/Queries', component: PostGresQueriesPageComponent },
  { path: 'postgres/Alter', component: PostGresAlterPageComponent },
  { path: 'postgres/Joins', component: PostGresJoinsPageComponent },
  { path: 'postgres/Views', component: PostGresViewsPageComponent },
  { path: 'postgres/Functions', component: PostGresFunctionsPageComponent },
  { path: 'postgres/Procedures', component: PostGresProceduresPageComponent },
  { path: 'spring/SpringWhatIs', component: SpringWhatIsPageComponent },
  { path: 'spring/SpringAnnotations', component: SpringAnnotationsPageComponent },
  { path: 'spring/SpringBoot', component: SpringbootPageComponent },
  { path: 'spring/SpringSwagger', component: SpringSwaggerPageComponent },
  { path: 'spring/SpringSecurity', component: SpringSecurityPageComponent },
  { path: 'spring/SpringKafka', component: SpringKafkaPageComponent },
  { path: 'spring/SpringSockets', component: SpringWebSocketsPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [
  HomePageComponent,
  AboutPageComponent,
  GalleryPageComponent,
  TutorialsPageComponent,
  PortfolioPageComponent,
  JavaGettingStartedPageComponent
 ];
