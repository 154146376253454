import {Component, OnInit} from '@angular/core';
import postgresText from '../../../../../../assets/js/tutorial-texts/PostgresTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-postgres-alter',
  templateUrl: './postgres-alter.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class PostGresAlterPageComponent implements OnInit {

  header = postgresText.Alter.header;
  steps: Step[] = postgresText.Alter.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {PostGresAlterPageComponent};
