import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.less']
})
class HomePageComponent implements OnInit {

  gifDone = false;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'black';
    setTimeout(() => this.gifDone = true, 6_000);
  }
}

export { HomePageComponent };
