<div class="code-page-tutorial-lesson-home" style="margin-bottom: 50px">
  <div class="code-page-background"></div>
  <div class="code-page-tutorial-lesson-container">
    <div class="row no-margins">
      <div class="col-sm-12 code-page-tutorial-lesson-headline">
        <h1>{{header}}</h1>
      </div>
    </div>
    <div class="row no-margins">
      <div class="col-sm-12 no-padding">
        <div *ngFor="let step of steps; let i = index" class="code-page-tutorial-lesson-step" [ngClass]="i % 2 == 0 ? 'code-page-tutorial-lesson-smoke-background' : ''">
          <div *ngIf="!step.hasImages" class="row">
            <div class="col-sm-12">
              <h1 class="animated fadeIn">{{step.header}}</h1>
              <p *ngFor="let informationText of step.informationTexts" class="animated fadeIn" [innerHTML]='informationText'></p>
            </div>
          </div>

          <div *ngIf="step.hasImages && i % 2 !== 0" class="row">
            <div class="col-sm-6">
              <img *ngFor="let src of step.imageSources" class="animated fadeIn" src="{{src}}" alt="">
            </div>
            <div class="col-sm-6">
              <h1 class="animated fadeIn">{{step.header}}</h1>
              <p *ngFor="let informationText of step.informationTexts" class="animated fadeIn" [innerHTML]='informationText'></p>
            </div>
          </div>

          <div *ngIf="step.hasImages && i % 2 === 0" class="row">
            <div class="col-sm-6">
              <h1 class="animated fadeIn">{{step.header}}</h1>
              <p *ngFor="let informationText of step.informationTexts" class="animated fadeIn" [innerHTML]='informationText'></p>
            </div>
            <div class="col-sm-6">
              <img *ngFor="let src of step.imageSources" class="animated fadeIn" src="{{src}}" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
