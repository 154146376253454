import {Component, OnInit} from '@angular/core';
import htmlText from '../../../../../../assets/js/tutorial-texts/HtmlTexts.json';
import {Step} from '../../../common/common-tutorial-page.component';

@Component({
  selector: 'app-html-getting-started',
  templateUrl: './html-getting-started.component.html',
  styleUrls: ['../../../../../tutorial.component.less']
})
class HtmlGettingStartedPageComponent implements OnInit {

  header = htmlText.GettingStarted.header;
  steps: Step[] = htmlText.GettingStarted.steps;

  ngOnInit(): void {
    $('body')[0].style.backgroundColor = 'white';
  }
}

export {HtmlGettingStartedPageComponent};
